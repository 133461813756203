import React from "react";
import { lpHeaderCss } from "./lp-header.style";
import kiara_logo from '../../../img/kiara_logo.svg'

export class LpHeader extends React.Component {
  render() {
    let pathname = window.location.pathname;
    let match = (path) => pathname.substring(pathname.indexOf('/')).indexOf(path) > -1;

    return (
      <nav className={`${lpHeaderCss} navbar navbar-expand-md fixed-top navbar-light bg-light`}>
        <div className="container">
          <a className="navbar-brand" href="/">
            <img className="logo-image" alt="kiara-logo" src={kiara_logo} />
          </a>
          <p className="logo-text">Kiara</p>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>

          <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className={`nav-item ${pathname === "/" ? "active" : ""}`}>
                <a className="nav-link" href="/">Home</a>
              </li>
              <li className={`nav-item ${pathname === "/sign-in" ? "active" : ""}`}>
                <a className="nav-link" href="/sign-in">Sign-in</a>
              </li>
              <li className={`nav-item ${match("/help") ? "active" : ""}`}>
                <a className="nav-link" href="https://www.notion.so/jenioinc/Kiara-Manual-FAQ-560d42558d234d9cbf268588f8c61dd1" target="_blank">Help</a>
              </li>
              <li className={`nav-item ${pathname === "/pricing" ? "active" : ""}`}>
                <a className="nav-link" href="/pricing">Pricing</a>
              </li>
              <li>
                <a className="nav-link" href="https://www.notion.so/jenioinc/Kiara-Overview-313d75feffea4d839b66c232f6220999" target="_blank">Overview</a>
              </li>
              <li>
                <a className="nav-link" href="https://medium.com/kiara-blog" target="_blank">Blog</a>
              </li>
              <li>
                <a className="nav-link" href="https://www.jenio.co/" target="_blank">About Us</a>
              </li>
              <li className={`nav-item ${match("/support") ? "active" : ""}`}>
                <a className="nav-link" href="/support">Support</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}
