import React from "react";
import {css} from "emotion";
import { separatorContentCss } from "../styles/main";

let lpContentAccelerators = css`
  margin: 50px auto;
  
  & > .benefits-header {
    font-size: 20px;
    .main-text {
      font-size: 36px;
      color: #e63d3d;
    }
  }
  
  & > .benefits-list {
    margin-top: 20px;
    
    .benefits-list-item {
      margin-top: 20px;
      .item-title {
        color: #e63d3d;
        font-size: 24px;
      }
      .item-title a {
        color: #e63d3d;
        text-decoration: none 
      }

      img {
        height: 110px;
      }
      
      .item-desc {
        font-weight: 700;
      }
    }
  }
`;

export class LpContentAccelerators extends React.Component {
  render() {

    const list = [
      {
        icon: "https://user-images.strikinglycdn.com/res/hrscywv4p/image/upload/c_limit,fl_lossy,h_1440,w_720,f_auto,q_auto/108339/618819_240395.png",
        title: "Demo Day",
        url: "https://jp.techcrunch.com/2018/12/11/500-kobe-accelerator/"
      },
      {
        icon: "https://user-images.strikinglycdn.com/res/hrscywv4p/image/upload/c_limit,fl_lossy,h_1440,w_720,f_auto,q_auto/1141286/455263_225242.png",
        title: "Profile",
        url: "https://www.startupschool.org/companies/kiara-app"
      }
    ];

    return (
      <div className={`${lpContentAccelerators}`}>
        <div className="benefits-header text-center">
          <div className="main-text">Accelerators</div>
          <div className="sub-text">We graduated from startup bootcamps</div>
        </div>

        <div className="benefits-list">
          <div className="row">
            {list.map((item, i) => (
              <div key={i} className="benefits-list-item col-md text-center">
                <a href={item.url}>
                  <img src={item.icon} alt={item.title} />
                </a>
                <div className="item-title">
                  <a href={item.url}>
                    {item.title}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={separatorContentCss}><div className="separator"/></div>
      </div>
    )
  }
}
