import React from "react";
import {css} from "emotion";

import logo from '../../../img/top-background.png'

let lpContentHeaderCss = css`
  background-repeat: no-repeat;
  background-color: transparent;
  background-image: url(${logo});
  background-size: cover;
  height: 852px;
  position: relative;
  
  &:before {
    background: rgba(0,0,0,.39);
    content: "";
    display: block!important;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform-style: preserve-3d;
    width: 100%;
  }
  
  @media (min-width: 769px) {
    height: 652px;
  }
  
  & > div {
    .row {
      padding: 65px 0;
      
      .left-side {
        color: #fff;
        font-size: 25px;
        
        div {
          margin-bottom: 15px;
        }
        
        .title {
          font-size: 55px;
        }
      }
    }
  }
`;

export class LpContentHeader extends React.Component {
  render() {
    return (
      <div className={lpContentHeaderCss}>
        <div className="container">
          <div className="row">
            <div className="col-md left-side">
              <div className="title">
                Kiara
              </div>

              <div>
                Real-time language translation for <br/>
                Slack, with support for 100 languages
              </div>

              <div>
                You can communicate with anyone in the world
              </div>

              <div>
                Slackユーザー向け同時翻訳Chatbot<br/>
                世界中の誰とでも話せます
              </div>

              <script async src="https://www.googletagmanager.com/gtag/js?id=AW-851025208"></script>
              <script>
                window.dataLayer = window.dataLayer || [];
                function gtag(){window.dataLayer.push(arguments)}
                gtag('js', new Date());
                gtag('config', 'AW-851025208');
              </script>
              <div>
                <a href="https://slack.com/oauth/v2/authorize?client_id=391116023478.580871805239&scope=app_mentions:read,channels:history,channels:read,chat:write,groups:history,groups:write,im:history,im:write&user_scope=channels:write,groups:write&state=production">
                  <img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" />
                </a>
              </div>
            </div>

            <div className="col-md">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/93W3PKuVKQU" frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen></iframe>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
