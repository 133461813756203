import React from "react";
import { css } from "emotion";

let lpFooterCss = css`
.footer{
    border: 3px solid rgb(221, 221, 221);

}

.footer-links{
    margin: 80px 0px 0px;
    font-size: 14px;
}

.footer-links > div{
    margin: 10px;
}

.footer-links > div > p{
    margin: 0;
}

.footer-links div p:first-child{
  font-weight: bold;
}

a{
    text-decoration: none;
}

.footer-links > div > p > a{
    color: #E63D3D;
}

.footer-text{
    margin: 50px 20px 0px;
    text-align: center;
}

.footer-text > p{
    margin: 0;
    font-size: 12px;
}

.fa-youtube{
    color: red;
    font-size: 30px;
}

@media screen and (min-width: 900px) {
    .footer-links{
        display: flex;
        justify-content: space-around;
        padding: 0px 80px;
    }
}
 
`;

export class LpFooter extends React.Component {
  render() {
    return (
      <div className={`footer ${lpFooterCss}`}>
        <div className="footer-links">
          <div>
            <p>About Us</p>
            <p><a href="http://">Mission</a></p>
            <p><a href="">Company</a></p>
          </div>
          <div>
            <p>Legal</p>
            <p><a href="http://">Privacy policy</a></p>
            <p><a href="">Terms of service</a></p>
          </div>
          <div>
            <p>Contact</p>
            <p><a href="http://">hello@kiara.com</a></p>
          </div>
          <div>
            <span><a href="https://www.youtube.com/channel/UC6ykX3btu4x3czl4y7X1nRw"><i className="fab fa-youtube"></i></a></span>
          </div>
        </div>
        <div className="footer-text">
          <p>© <script>document.write(new Date().getFullYear())</script> Kiara Inc.</p>
          <p>zip150-0042 New Shibuya Mansion 202, 36-17 Udagawa-cho, Shibuya-ku, Tokyo, Japan TEL : +81-80-6306-2223</p>
          <p>Made in Tokyo🗼with Love 😍</p>
        </div>
      </div>
    )
  }
}
