import React from "react";
import {LpHeader} from "./landing-page/header/lp-header";
import {LpFooter} from "./landing-page/footer/lp-footer";
import {lpLanguageCss, lpStyle} from "./landing-page/styles/main";


export class Language extends React.Component {

    render() {
        return (
            <div className={lpStyle}>
                <LpHeader/>
                <div className="container">
                    <div className={lpLanguageCss}>
                        <div className="header">
                            <div>English + 120 Languages</div>
                        </div>
                        <div>
                            Add and rearrange any components you want.
                            <p>English + 120 Languages</p>
                            <p>Please translate with below bar</p>
                        </div>
                    </div>
                </div>
                <LpFooter/>
            </div>
        )
    }

}
