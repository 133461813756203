import React from "react";
import {Header} from "./admin-dashboard/Header";
import {DashboardLayoutCss, DashboardMainCss} from "./admin-dashboard/styles/main";
import {LpFooter} from "./landing-page/footer/lp-footer";
import {lpStyle} from "./landing-page/styles/main";
import SendDM from '../components/admin-dashboard/SendDM';

export class AdminDashboard extends React.Component {

    constructor() {
        super();
        this.state = {

        };
    }

    componentDidMount() {
        
    }

    render() {
        
        return (
            <div className={lpStyle + ' ' + DashboardMainCss + ' ' + DashboardLayoutCss}>
                <Header/>
                <div className="container form-container">
                    <div className="css-kxp0xy">
                        <div className="example">
                            <div className="current_plan">
                                <div className="left">
                                    <span>Hi Admin!</span>
                                </div>
                                <br />
                            </div>
                            <SendDM />
                        </div>
                    </div>
                </div>

                <LpFooter/>
            </div>
        )
    }
}
