import React from "react";
import {LpHeader} from "./landing-page/header/lp-header";
import {LpFooter} from "./landing-page/footer/lp-footer";
import {lpSupportCss, lpStyle} from "./landing-page/styles/main";

// --- Post bootstrap -----
import PropTypes from 'prop-types';
import axios from 'axios';

const styles = theme => ({
    form: {
        marginTop: theme.spacing.unit * 6,
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
    },
    feedback: {
        marginTop: theme.spacing.unit * 2,
    },
});

export class Support extends React.Component {

    constructor() {
        super();
        this.state = {
            data: {
                name: '',
                email: '',
                title: '',
                comment: ''
            }
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        var data = this.state.data;

        switch (event.target.name) {
            case 'name':
                data.name = event.target.value;
                break;
            case 'email':
                data.email = event.target.value;
                break;
            case 'message':
                data.message = event.target.value;
                break;
        }

        this.setState({
            data: data
        });
    }

    state = {
        infoText: ''
    };

    handleSubmit() {
        let data = {
            name: this.state.data.name,
            email: this.state.data.email,
            message: this.state.data.message,
        };

        axios.post('./mail/send', data)
            .then(res => {
                this.setState({infoText: 'Thank you for your message.  We will get back to you soon'}, this.resetForm(this.state.data));
            })
            .catch(() => {
                console.log('Message not sent')
            })

    };

    resetForm = (values) => {
        values.name = '';
        values.email = '';
        values.message = '';
    };

    render() {
        const {infoText} = this.state;

        return (
            <div className={lpStyle}>
                <LpHeader/>
                <div className="container">
                    <div className={lpSupportCss}>
                        <div className="header">
                            <div>Support</div>
                            <div>{infoText}</div>
                        </div>
                        <div>
                            Any Message is Welcome!
                        </div>
                        <form action="javascript:void(0)" onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <input name="name"
                                       className="message-name contact-input" type="text" placeholder="Your Name" value={this.state.name} onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <input name="email"
                                       className="message-email contact-input" type="email" placeholder="your@email.com" value={this.state.email} onChange={this.handleChange}
                                       required
                                       />

                            </div>
                            <div className="form-group">
                                <textarea name="message"
                                          className="message-input contact-input" type="text"
                                          placeholder="Please write your message here"
                                          value={this.state.message} onChange={this.handleChange}
                                          rows={10}
                                          required/>
                            </div>
                            <div className="button--container">
                                <button type="submit" className="email-button">Send</button>
                            </div>
                        </form>
                    </div>
                </div>
                <LpFooter/>
            </div>

        );
    }
}
