import { css } from 'emotion';

export const DashboardHeaderCss = css`
  background: #fff;
  box-shadow: 0 1px 0 rgba(0,0,0,.2);
  // min-height: 54px;
  // padding: 8px 0;
  // height: 70px;
  
  .navbar-brand {
    color: #e63d3d;
    font-size: 29px;
    img {
      max-width: 150px;
    }
  }
  
  .navbar-nav {
    padding: 10px 0;
    & > li {
      @media(min-width: 769px) {
        margin-left: 20px;
      }
      
      &.active {
        a {
          color: #e63d3d !important;
        }        
      } 
      
      & > a {
        color: #666;
        padding: 5px 0;
      }
    }
  }
`;

export const DashboardLayoutCss = css`
  body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  }

  .App {
    text-align: center;
  }

  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: #61dafb;
  }

  @-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }

  @keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }

  .css-1djbfb5 {
    background: #fff;
    box-shadow: 0 1px 0 rgba(0, 0, 0, .2);
  }
  .css-1djbfb5 .navbar-brand {
    color: #e63d3d;
    font-size: 29px;
  }
  .css-1djbfb5 .navbar-brand img {
    max-width: 150px;
  }
  .css-1djbfb5 .navbar-nav {
    padding: 10px 0;
  }
  @media (min-width: 769px) {
    .css-1djbfb5 .navbar-nav > li {
        margin-left: 20px;
    }
  }
  .css-1djbfb5 .navbar-nav > li.active a {
    color: #e63d3d !important;
  }

  .css-1djbfb5 .navbar-nav > li > a {
    color: #666;
    padding: 5px 0;
  }
  .css-1uscbiv {
    background-repeat: no-repeat;
    background-color: transparent;
    background-image: url(https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&h=1500&w=2000&fit=clip&fm=jpg);
    background-size: cover;
    height: 852px;
    position: relative;
  }
  .css-1uscbiv:before {
    background: rgba(0, 0, 0, .39);
    content: "";
    display: block !important;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    width: 100%;
  }
  @media (min-width: 769px) {
    .css-1uscbiv {
        height: 652px;
    }
  }
  .css-1uscbiv > div .row {
    padding: 65px 0;
  }
  .css-1uscbiv > div .row .left-side {
    color: #fff;
    font-size: 25px;
  }
  .css-1uscbiv > div .row .left-side div {
    margin-bottom: 15px;
  }
  .css-1uscbiv > div .row .left-side .title {
    font-size: 55px;
  }
  .css-sqvoit {
    margin: 50px auto;
  }
  .css-sqvoit > .benefits-header {
    font-size: 20px;
  }
  .css-sqvoit > .benefits-header .main-text {
    font-size: 36px;
    color: #e63d3d;
  }
  .css-sqvoit > .benefits-list {
    margin-top: 20px;
  }
  .css-sqvoit > .benefits-list .benefits-list-item {
    margin-top: 20px;
  }
  .css-sqvoit > .benefits-list .benefits-list-item .item-title {
    color: #e63d3d;
    font-size: 24px;
  }
  .css-sqvoit > .benefits-list .benefits-list-item .item-desc {
    font-weight: 700;
  }
  .css-oyyjpy {
    padding: 30px 0;
  }
  .css-oyyjpy > .header {
    font-size: 20px;
  }
  .css-oyyjpy > .header .main-text {
    font-size: 36px;
    color: #e63d3d;
  }
  .css-oyyjpy > .header-pricing {
    color: #e63d3d;
    font-size: 25px;
  }
  .css-oyyjpy > .list .list-item {
    margin-top: 20px;
  }
  .css-oyyjpy > .list .list-item img.gif {
    height: 200px;
    margin-bottom: 20px;
    width: 100%;
  }
  .css-oyyjpy > .list .list-item img.gif-add-to-slack {
    margin-bottom: 130px;
    width: 80%;
  }
  .css-oyyjpy > .list .list-item .item-title {
    color: #e63d3d;
    font-size: 24px;
  }
  .css-oyyjpy > .list .list-item .item-desc {
    font-weight: 700;
  }
  .css-12f91zp {
    padding: 10px 0;
    position: relative;
  }
  .css-12f91zp .separator {
    background: #ddd;
    border-radius: 2px;
    height: 3px;
    margin: 10px auto;
    width: 240px;
  }
  .css-g755kn {
    padding: 25px 0;
  }
  .css-g755kn .question-buttons {
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    padding: 24px 44px;
    font-weight: 700;
    margin: 0 auto;
    padding: 24px 44px;
    -webkit-text-decoration: none;
    text-decoration: none;
    text-shadow: 0 1px rgba(0, 0, 0, .2);
    background: #e63d3d;
    box-shadow: 0 2px 0 #c33434;
    font-size: 24px;
  }
  .css-wrsyzi {
    padding: 30px 0;
  }
  .css-wrsyzi > .header {
    font-size: 20px;
  }
  .css-wrsyzi > .header .main-text {
    font-size: 36px;
    color: #e63d3d;
  }
  .css-wrsyzi > .list .list-item {
    margin-top: 20px;
  }
  .css-wrsyzi > .list .list-item .item-title {
    color: #e63d3d;
    font-size: 24px;
  }
  .css-1i16i3t {
    padding: 30px 0;
  }
  .css-1i16i3t > .header {
    font-size: 20px;
  }
  .css-1i16i3t > .header .main-text {
    font-size: 36px;
    color: #e63d3d;
  }
  .css-1i16i3t > .list img {
    width: 110px;
    height: 110px;
    margin-right: 10px;
    margin-top: 20px;
  }
  .css-1gj6tdt {
    font-size: 13px;
  }
  .css-1gj6tdt > div {
    padding: 30px 0;
  }
  .css-1gj6tdt a {
    color: #e63d3d;
  }
  .css-1gj6tdt a .fab {
    font-size: 30px;
  }
  .css-1ga6jbl {
    font-family: 'Lato', sans-serif;
    margin-top: 70px;
  }
  .css-81ob28 {
    padding: 30px 0;
  }
  .css-81ob28 .header {
    color: #e63d3d;
    font-size: 30px;
  }
  .css-81ob28 a {
    color: #e63d3d;
  }
  .css-teqyad {
    padding: 30px 0;
    text-align: center;
  }
  .css-teqyad .header {
    color: #e63d3d;
    font-size: 30px;
  }
  .css-teqyad a {
    color: #e63d3d;
  }
  .css-kxp0xy {
    padding: 30px 0;
    text-align: center;
  }
  .css-kxp0xy .header {
    color: #e63d3d;
    font-size: 30px;
  }
  .css-kxp0xy a {
    color: #e63d3d;
  }
  .css-kxp0xy * {
    box-sizing: border-box;
  }
  .css-kxp0xy input[type=text], .css-kxp0xy input[type=email], .css-kxp0xy select, .css-kxp0xy textarea {
    width: 50%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
  }
  .css-kxp0xy input[type=submit] {
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .css-kxp0xy input[type=submit]:hover {
    background-color: #45a049;
  }
  .css-kxp0xy .container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
  }
  .css-dojam2 * {
    box-sizing: border-box;
  }
  .css-dojam2 body, .css-dojam2 html {
    background-color: #f6f9fc;
    font-size: 18px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  }
  .css-dojam2 h1 {
    color: #32325d;
    font-weight: 400;
    line-height: 50px;
    font-size: 40px;
    margin: 20px 0;
    padding: 0;
  }
  .css-dojam2 .Checkout {
    margin: 0 auto;
    max-width: 800px;
    box-sizing: border-box;
    padding: 0 5px;
  }
  .css-dojam2 label {
    color: #6b7c93;
    font-weight: 300;
    -webkit-letter-spacing: 0.025em;
    -moz-letter-spacing: 0.025em;
    -ms-letter-spacing: 0.025em;
    letter-spacing: 0.025em;
    display: inline;
  }
  .css-dojam2 button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    -webkit-letter-spacing: 0.025em;
    -moz-letter-spacing: 0.025em;
    -ms-letter-spacing: 0.025em;
    letter-spacing: 0.025em;
    background-color: #6772e5;
    -webkit-text-decoration: none;
    text-decoration: none;
    -webkit-transition: all 150ms ease;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    margin-top: 10px;
  }
  .css-dojam2 form {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 3px solid #e6ebf1;
  }
  .css-dojam2 button:hover {
    color: #fff;
    cursor: pointer;
    background-color: #7795f8;
    -webkit-transform: translateY(-1px);
    -ms-transform: translateY(-1px);
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
  }
  .css-dojam2 input, .css-dojam2 .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: 'Source Code Pro', monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
  }
  .css-dojam2 input::-webkit-input-placeholder {
    color: #aab7c4;
  }
  .css-dojam2 input::-moz-placeholder {
    color: #aab7c4;
  }
  .css-dojam2 input:-ms-input-placeholder {
    color: #aab7c4;
  }
  .css-dojam2 input::placeholder {
    color: #aab7c4;
  }
  .css-dojam2 input:focus, .css-dojam2 .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }
  .css-dojam2 .StripeElement.IdealBankElement, .css-dojam2 .StripeElement.PaymentRequestButton {
    padding: 0;
  }
`;

export const DashboardMainCss = css`
  .form-container {
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    max-width: 100%;
    background-color: #f0f0f0; }
    .form-container .css-kxp0xy {
      width: 70%;
      max-width: 1000px;
      margin: 0 auto;
      text-align: left; }
    .form-container .css-kxp0xy .current_plan {
        margin-top: 40px;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        -ms-justify-content: space-between;
        justify-content: space-between;
        -ms-flex-pack: space-between;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        font-size: 2.5rem;
        color: #fff;
        padding: 15px 30px;
        background-color: #F7A600;
        border-radius: 10px; }
        .form-container .css-kxp0xy .current_info {
            margin-top: 10px;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-wrap: wrap;
            -moz-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-justify-content: space-between;
            -moz-justify-content: space-between;
            -ms-justify-content: space-between;
            justify-content: space-between;
            -ms-flex-pack: space-between;
            -webkit-align-items: center;
            -moz-align-items: center;
            -ms-align-items: center;
            align-items: center;
            font-size: 1.5rem;
            color: #fff;
            padding: 5px 10px;
            background-color: rgb(62, 172, 12);
            border-radius: 10px; }
    .form-container .css-kxp0xy .current_plan .left {
          font-size: 1.8rem; }
    .form-container .css-kxp0xy .current_plan .right {
          font-size: 3rem; }
    .form-container .css-kxp0xy .form-content {
        width: 100%;
        max-width: 1000px;
        margin: 20px auto;
        background: #fff;
        box-shadow: 0 1px 3px 0 #e6ebf1;
        border-radius: 10px;
        padding: 50px 20px;
        color: #3B415E;
        text-align: left; }
    .form-container .css-kxp0xy .form-content label {
          text-align: left !important;
          margin-bottom: 10px;
          font-size: 1rem; }
    .form-container .css-kxp0xy .form-content .header {
          color: #3B415E;
          text-align: center;
          margin-bottom: 50px; }
    .form-container .css-kxp0xy .form-content .header h2 {
            font-size: 2.5rem;
            margin-bottom: 20px; }
    .form-container .css-kxp0xy .form-content .header .formDescription {
            font-size: 1.4rem;
            text-align: center;
            margin: 0px; }
    .form-container .css-kxp0xy .form-content .form-content-inner {
          max-width: 600px;
          width: 100%;
          margin: 0 auto; }
    .form-container .css-kxp0xy .form-content .form-content-inner .Stripe {
            text-align: center;
            border-bottom: solid 1px #eee;
            padding-bottom: 10px; }
    .form-container .css-kxp0xy .form-content .form-content-inner .Stripe label {
              text-align: left !important;
              margin-bottom: 10px; }
    .form-container .css-kxp0xy .form-content .form-content-inner .Stripe .StripeElement {
              max-width: 600px;
              margin: 10px auto;
              text-align: center;
              border: solid 1px #DEDEDE;
              height: 60px;
              box-sizing: border-box;
              padding: 20px 12px;
              border-radius: 4px;
              background-color: white;
              box-shadow: 0 1px 3px 0 #e6ebf1;
              -webkit-transition: box-shadow 150ms ease;
              transition: box-shadow 150ms ease; }
    .form-container .css-kxp0xy .form-content .form-content-inner .Stripe .StripeElement iframe {
                width: 580px; }
    .form-container .css-kxp0xy .form-content .form-content-inner .Stripe .StripeElement--focus {
              box-shadow: 0 1px 3px 0 #cfd7df; }
    .form-container .css-kxp0xy .form-content .form-content-inner .Stripe .StripeElement--invalid {
              border-color: #fa755a; }
    .form-container .css-kxp0xy .form-content .form-content-inner .Stripe .StripeElement--webkit-autofill {
              background-color: #fefde5 !important; }
    .form-container .css-kxp0xy .form-content .form-content-inner .user-form {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-wrap: wrap;
            -moz-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-justify-content: space-between;
            -moz-justify-content: space-between;
            -ms-justify-content: space-between;
            justify-content: space-between;
            -ms-flex-pack: space-between;
            margin: 0 auto;
            max-width: 600px;
            margin-top: 30px; }
    .form-container .css-kxp0xy .form-content .form-content-inner .user-form input[type=text],
            .form-container .css-kxp0xy .form-content .form-content-inner .user-form input[type=email],
            .form-container .css-kxp0xy .form-content .form-content-inner .user-form select,
            .form-container .css-kxp0xy .form-content .form-content-inner .user-form textarea {
              width: 100%; }
    .form-container .css-kxp0xy .form-content .form-content-inner .user-form .user-form-input {
              width: 48%; }
    .form-container .css-kxp0xy .form-content .form-content-inner .user-form .user-form-select-1 {
              width: 48%; }
    .form-container .css-kxp0xy .form-content .form-content-inner .user-form .user-form-select-2 {
              width: 100%; }
    .form-container .css-kxp0xy .form-content .form-content-inner .user-form .user-form-email {
              width: 100%; }
    .form-container .css-kxp0xy .form-content .form-content-inner .user-form .custom-select {
              padding: .375rem 1.75rem .375rem;
              height: calc(2.5em + .75rem + 2px); }
    .form-container .css-kxp0xy .form-content .form-content-inner .form-btn {
            max-width: 600px;
            width: 100%;
            margin: 0 auto;
            text-align: center; }
    .form-container .css-kxp0xy .form-content .form-content-inner .form-btn .btn {
              border: solid 1px #425BB8;
              background: #5878EE;
              color: #fff;
              width: 330px;
              border-radius: 6px;
              padding: 15px 0;
              font-weight: bold; }
    .form-container .css-kxp0xy .form-content .form-content-inner .form-btn .btn:hover {
              background: #546FD1; }
    @media (max-width: 767.98px) {
    .form-container .css-kxp0xy {
      width: 95%;
      padding: 30px 0 0px 0; }
      .form-container .css-kxp0xy .current_plan {
        margin-top: 20px;
        font-size: 1.4rem; }
        .form-container .css-kxp0xy .current_plan .left {
          font-size: 1.1rem; }
        .form-container .css-kxp0xy .current_plan .right {
          font-size: 2rem; }
      .form-container .css-kxp0xy .form-content .header h2 {
        font-size: 1.5rem; }
      .form-container .css-kxp0xy .form-content .header .formDescription {
        font-size: 1rem; }
      .form-container .css-kxp0xy .form-content .form-content-inner .Stripe {
        text-align: center;
        border-bottom: solid 1px #eee;
        padding-bottom: 10px; }
        .form-container .css-kxp0xy .form-content .form-content-inner .Stripe label {
          text-align: left !important;
          margin-bottom: 10px; }
        .form-container .css-kxp0xy .form-content .form-content-inner .Stripe .StripeElement {
          max-width: 360px;
          width: 100%; }
          .form-container .css-kxp0xy .form-content .form-content-inner .Stripe .StripeElement iframe {
            width: 370px;
            width: 100%; }
          .form-container .css-kxp0xy .form-content .form-content-inner .Stripe .StripeElement input {
            width: 100%; }
        .form-container .css-kxp0xy .form-content .form-content-inner .Stripe .StripeElement--focus {
          box-shadow: 0 1px 3px 0 #cfd7df; }
        .form-container .css-kxp0xy .form-content .form-content-inner .Stripe .StripeElement--invalid {
          border-color: #fa755a; }
        .form-container .css-kxp0xy .form-content .form-content-inner .Stripe .StripeElement--webkit-autofill {
          background-color: #fefde5 !important; }
      .form-container .css-kxp0xy .form-content .form-content-inner .user-form {
        display: block; }
        .form-container .css-kxp0xy .form-content .form-content-inner .user-form .user-form-input {
          width: 100%; }
        .form-container .css-kxp0xy .form-content .form-content-inner .user-form .user-form-select-1 {
          width: 100%; }
        .form-container .css-kxp0xy .form-content .form-content-inner .user-form .user-form-select-2 {
          width: 100%; }
        .form-container .css-kxp0xy .form-content .form-content-inner .user-form .user-form-email {
          width: 100%; }
      .form-container .css-kxp0xy .form-content .form-content-inner .form-btn {
        max-width: 100%; }
        .form-container .css-kxp0xy .form-content .form-content-inner .form-btn .btn {
          width: 100%; } }

`;