import React, {Component} from 'react';
import { CardElement, injectStripe, CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    StripeProvider,
    Elements, } from 'react-stripe-elements';
import {DashboardLayoutCss, DashboardMainCss} from "../containers/dashboard/styles/main";
import GA from '../utils/GoogleAnalytics';
import cvcTip from '../img/cvcTip.jpeg'

const createOptions = () => {
    return {
      style: {
        base: {
          fontSize: '16px',
          color: '#424770',
          letterSpacing: '0.025em',
          '::placeholder': {
            color: '#aab7c4',
          },
          width: '50%',
          padding: '12px',
          border: '1px solid #ccc',
          'border-radius': '4px',
          'box-sizing': 'border-box',
          'margin-top': '6px',
          'margin-bottom': '16px',
          resize: 'vertical',
        },
        invalid: {
          color: '#c23d4b',
        },
      },
    };
  };

class CheckoutForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            processingPayment: false,
            email: '',
            language: 'en',
            industry: 'it',
            position: 'it_development',
            employee: '10',
            region: 'asia',
            updateData: !this.props.isPay,
            hover: false
        };
        this.submit = this.submit.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
        this.handleIndustryChange = this.handleIndustryChange.bind(this);
        this.handleFunctionChange = this.handleFunctionChange.bind(this);
        this.handleEmployeeChange = this.handleEmployeeChange.bind(this);
        this.handleRegionChange = this.handleRegionChange.bind(this);
        this.handleMouseIn = this.handleMouseIn.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);
    }

    handleEmailChange(event) {
        this.setState({email: event.target.value});
    }
    handleLanguageChange(event) {
        this.setState({language: event.target.value});
    }
    handleIndustryChange(event) {
        this.setState({industry: event.target.value});
    }
    handleFunctionChange(event) {
        this.setState({position: event.target.value});
    }
    handleEmployeeChange(event) {
        this.setState({employee: event.target.value});
    }
    handleRegionChange(event) {
        this.setState({region: event.target.value});
    }

    handleMouseIn() {
        this.setState({ hover: true })
    }
    
    handleMouseOut() {
        this.setState({ hover: false })
    }

    async submit(ev) {
        this.setState({processingPayment: true})
        const msg = this.props.isPay ? 
            "Are you sure want to change to the personal plan?" :
            "Are you sure want to change your credit card?";
            
        const confirm = window.confirm(msg);
        if (confirm) {
            const gaActions = this.props.isPay ? 
                "Change to personal plan" :
                "Change credit card data";
            GA.Event('Dashboard', gaActions, 'Payment');
            
            let {token} = await this.props.stripe.createToken({name: "Kiara-Payment"});
            const formData = this.props.isPay ? {
                'email': this.state.email,
                'language': this.state.language,
                'industry': this.state.industry,
                'position': this.state.position,
                'employee': this.state.employee,
                'region': this.state.region,
                'updateData': this.state.updateData
            } : {
                'email': this.state.email,
                'updateData': this.state.updateData
            };
            let response = await fetch("/api/plan/change", {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'plan': 1,
                    'source': token,
                    'formData': formData
                })
            });

            if (response.ok) {
                this.setState({processingPayment: false});
                this.props.getAccount();
                this.props.setCompletePayment();
            }
        }
    }

    render() {
        const {isPay} = this.props;
        const tooltipStyle = {
            display: this.state.hover ? 'block' : 'none'
          }
        return (
            <div className={DashboardMainCss + ' ' + DashboardLayoutCss}>

                {
                    (isPay ? (
                        <p></p>
                    ) : (
                        <p>Please input your new credit card and email</p>
                    ))
                }
                <div className="user-form">

                    <div className="user-form-email">
                        <label>Card Details</label>
                        <CardNumberElement
                            {...createOptions()}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="user-form-select-1">
                        <label>Card Expiration Date</label>
                        <CardExpiryElement
                            {...createOptions()}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="user-form-select-1">
                        <label className="cvcTooltip" 
                            onMouseOver={this.handleMouseIn.bind(this)} 
                            onMouseOut={this.handleMouseOut.bind(this)}
                        > 
                            CVC <i className="fas fa-question-circle"></i>
                            <span style={tooltipStyle} className="tooltiptext">
                                3-digit security code usually found on the back of your card.
                                <img src={cvcTip} alt="cvc-tip" />
                            </span> 
                        </label>

                        <CardCVCElement {...createOptions()} onChange={this.handleChange} />
                    </div>
                </div>

                {
                    (isPay ? (
                        <div className="user-form">
                            <div className="user-form-email">
                                <label>Email</label>
                                <p>
                                    <input type="email" value={this.state.email} onChange={this.handleEmailChange} id="email" name="email" placeholder="Your email" required="" />
                                </p>
                            </div>
                        </div>
                        
                    ) : (
                        <div className="user-form">
                            <div className="user-form-email">
                                <label>Email</label>
                                <p>
                                    <input type="email" value={this.state.email} onChange={this.handleEmailChange} id="email" name="email" placeholder="Your email" required="" />
                                </p>
                            </div>
                        </div>
                    ))
                }
                <div className="form-btn">
                    <button className="btn btn-default" disabled={this.state.processingPayment} onClick={this.submit}>
                        {
                            (isPay ? (
                                'Change to Personal Plan'
                            ) : (
                                'Update Payment Details'
                            ))
                        }
                    </button>
                </div>

            </div>
        );
    }
}

CheckoutForm.defaultProps = {
    isPay: true
}

export default injectStripe(CheckoutForm);
