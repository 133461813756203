import React from "react";
import { LpFooter } from "./landing-page/footer/lp-footer";
import { LpHeader } from "./landing-page/header/lp-header";
import { lpStyle, lpPrivacyCss } from "./landing-page/styles/main";

export class Terms extends React.Component {
  render() {
    return (
      <div className={lpStyle}>
        <LpHeader />
        

        <div className="container">
          <div className={lpPrivacyCss}>
            <div>
              <div className="header">Terms of Service</div>
              <div className="date">Effective: April 10, 2019</div>
            </div>

            <div>
              <br/>
              This Agreement of Services (“Agreement”), governs
              “Kiara”, a multi-language translation bot service provided on Slack®, including Kiara website and other
              Jenio’s
              websites provided and to be provided by Jenio Inc. (“Jenio”) (collectively, “Services”).
            </div>

            <div>
              <br/>
              For the avoidance of doubt, any services or
              functions provided by Slack Technologies, Inc. (collectively “Slack®”) are governed by Slack® <a
              target="_blank"
              href="https://stripe.com/us/legal">Customer
              Terms of Service</a>, and not be construed as a part of Kiara nor other services provided by Jenio.
            </div>


            <div>
              <br/>
              1.Enterprise user
              <br/><br/>
              If you are entering into this Agreement on behalf
              of a legal entity such as company or school, you represent that you have the authority to bind said legal
              entity
              <br/><br/>
              (“Enterprise”) to this Agreement, in which case references to “you” in this Agreement shall mean said
              Enterprise. If you do not have such authority, you must not accept this Agreement and may not use
              Services.
              <br/><br/>
              You and other users who access and make use of
              Services as members of Enterprise (“Enterprise Members”) are jointly responsible for Enterprise Users’ use of
              Services as well as compliance with this Agreement. Any violation of this Agreement by any of Enterprise
              Members shall be deemed to be a violation thereof by you.
              <br/>
            </div>

            <div>
              <br/>
              2.Licenses
              <br/><br/>
              In consideration for your agreement to this Agreement and your payment of all
              applicable Fees, Jenio grants you a limited, non-exclusive, non-sublicensable, non-transferable, revocable
              license to access and make use of Services.
              <br/>
            </div>

            <div>
              <br/>
              3.Signup
              <br/><br/>
              By completing the signup process for Kiara, you are agreeing to subscribe to Kiara,
              subject to the terms and conditions of this Agreement. You agree to provide Jenio with accurate and complete
              information for signup and to promptly notify Jenio if and when any changes to any such information
              arises.
            </div>

            <div>
              <br/>
              4.Fees
              <br/><br/>
              For Jenio’s counting up a number of characters
              translated by Kiara, any and all characters posted by all individuals and bots in a single Slack® room
              designated by you are subject to said counting up for billing of Services.
              <br/>
              a.Trial
              <br/><br/>

              If you have signed up to use Services on a
              trial basis, then you may use Services and Software up to the number of characters per month as described
              on <a target="_blank" href="https://www.kiara-app.com/">Jenio’s</a><a target="_blank" href="https://www.kiara-app.com/"> website</a>.

              <br/>
              b.Fees
              <br/><br/>

              Your (and Enterprise Users’ if applicable)
              access to or use of Services shall be contingent upon your payment of all applicable fees as described on
              Jenio’s website (“Fees”). Upon your signup for Services, you will be required to designate a valid payment
              method on <a target="_blank" href="https://stripe.com/payments/payment-methods-guide">Stripe</a>®. You hereby
              authorize Jenio to charge to your designated account all Fees relating to Services you select, and you agree
              to pay all such Fees via Stripe® payment service.
              <br/><br/>
              Jenio may revise its Fees, including by increasing or adding new Fees, at any time on
              fourteen (14) days’ prior notice. Such notice may be sent to you via email, by posting on the <a
              target="_blank" href="https://www.kiara-app.com/">Jenio’s</a><a target="_blank" href="https://www.kiara-app.com/"> website</a> or
              any other manner chosen by Jenio in its sole discretion. Your use of Services after the fourteen (14) day
              notice period shall be deemed to be your acceptance of said notice and constitutes your acceptance of the new
              or revised Fees. If you do not agree to the revised Fees, you may cancel your subscription by following the
              instructions below.
              <br/><br/>
              c.Renewal
              <br/><br/>

              Your subscriptions of Services is and will be
              renewed automatically at the subscription level(s) and Fees in effect at the time the then-current
              subscription term ends. you may cancel your subscription by following the instructions below.
            </div>

            <div>
              <br/>
              5.Termination and refund

              <div>
                <br/>
                a.Your cancellation
                <br/><br/>
                You may cancel your subscription at any time
                from your account settings you can access by signing in Kiara’s website.
                <br/><br/>

                Except as may be expressly set forth herein,
                all Fees paid or accrued in connection with any Services are non-refundable, and Jenio will not prorate any
                Fees paid for a subscription that is terminated before the end of its term.
              </div>

              <div>
                <br/>
                b.Termination by Jenio
                <br/><br/>
                Jenio may deny you access to all or any part of
                Services or terminate your account with or without prior notice if you engage in any conduct or activities
                that Jenio determines, in its sole discretion, violate this Agreement or the rights of Jenio or any third
                party, or is otherwise inappropriate.
              </div>

              <div>
                <br/>
                c.Upon termination
                <br/><br/>
                Upon termination of your account (or the account of Enterprise which you are its
                Enterprise User) for any reason, your right to use the Services will immediately cease.
                <br/><br/>
                All provisions of this Agreement that by their
                nature should survive termination shall survive termination, including, without limitation, ownership
                provisions, warranty disclaimers, indemnity, limitations of liability and miscellaneous provisions.
              </div>
            </div>

            <div>
              <br/>
              6.Access to Services
              <br/><br/>
              You are solely responsible for obtaining and
              maintaining any equipment and ancillary services needed to connect, access or otherwise use Services,
              including, but not limited to, modems, hardware, software, internet service, telecommunications capacity, and
              Slack® account.
            </div>

            <div>
              <br/>
              7.User Content
              <div>
                <br/>
                a.Your responsibility
                <br/><br/>
                You are solely responsible for all text or
                other content you upload, send or receive in connection with Services (“User Content”). Jenio is not
                responsible for the loss, corruption or other changes to User Content.
              </div>

              <div>
                <br/>
                b.License of User Content
                <br/><br/>
                You represent and warrant that you own or have
                a valid and enforceable license to use all User Content and that no User Content infringes, misappropriates or
                violates the rights (including, but not limited to any intellectual property rights) of any person or
                entity.
                <br/><br/>
                By uploading or sending any User
                Content, you give Jenio a nonexclusive, worldwide, royalty-free and fully-paid, transferable and
                sublicensable, perpetual, and irrevocable license to copy, store, use and translate your User Content in
                connection with the provision of Services and to improve the algorithms underlying Services.
              </div>
            </div>


            <div>
              <br/>
              8.Changes to Services or Agreement

              <div>
                <br/>
                We may modify the terms and conditions of this Agreement at any time on fourteen (14) days’ prior notice. Such notice may be sent to you
                via email, by posting on the Jenio’s website or any other manner chosen by Jenio in its sole discretion. Your
                use of Services after the fourteen (14) day notice period shall be deemed to be your acceptance of said notice
                and constitutes your acceptance of the new or modified Agreement. If you do not agree to the modified
                Agreement, you may cancel your subscription by following the instructions above.
              </div>
              <div>
                <br/>
                Jenio may at any time to
                <br/>
              </div>
              i.change any
              information, specifications, features or functions of Services;
              <br/>
              ii.suspend or discontinue, temporarily or permanently, any or all of
              Services, including the availability of any feature, database or content; or
              <br/>
              iii.impose limits on certain features and Services or restrict access
              to parts or all of Services,
              in each case with or without prior notice and
              without any liability to you or any third party.
            </div>

            <div>
              <br/>
              9Links to third party sites
              <br/><br/>
              Any and all services offered or provided by third parties, including, but not
              limited to Slack®, are not a part of Services or any other services offered or provided by Jenio. Jenio does
              not warrant or make any representation regarding the legality, accuracy or authenticity of content presented
              by such services offered by third parties and shall have no liability for any loss or damages arising from the
              access or use of such services.
            </div>

            <div>
              <br/>
              10.Consent to receive email
              <div>
                <br/>
                Jenio may contact you with direct marketing messages such as product announcements,
                software updates, and special offers. You may opt out of such messages at any time by clicking the
                “unsubscribe” link found within Jenio’s messages and changing your contact preferences. You will continue to
                receive transactional messages related to Services, even if you unsubscribe from marketing messages.
                <br/><br/>
                For EEA users: Jenio only sends marketing
                messages to users located in the EEA with your prior consent.
              </div>
            </div>

            <div>
              <br/>
              11.Data collection and privacy
              <div>
                <br/>
                Jenio’s information practices are described in
                and in accordance with <a target="_blank" href="https://kiara-app.com/privacy-policy">Jenio Privacy Policy</a>.
              </div>
            </div>

            <div>
              <br/>
              12.Ownership

              <div>
                <br/>
                a.Each IP rights
                <br/><br/>

                All intellectual property rights (“IPR”) in and
                to your User Content are and shall remain your property, and Jenio shall acquire no right of ownership with
                respect to your User Content.
                <br/><br/>
                All IPR in
                and to Services and other Jenio’s IPR is and shall remain the sole property of Jenio (and its licensors if
                applicable), and you shall acquire no right of ownership or use with respect to them except as specified in
                this Agreement.
              </div>

              <div>
                <br/>
                b.Your voluntary feedback
                <br/><br/>
                You may from time to time
                provide Jenio with suggestions, comments, recommendations, improvements, solutions, bug fixes, features,
                concepts, techniques, ideas, know-how and/or any feedback regarding Services and/or any of Jenio’s related
                technologies (“Feedback”). All Feedback shall be exclusively owned by Jenio, and you hereby make all
                assignments necessary to accomplish the foregoing ownership.
              </div>
            </div>

            <div>
              <br/>
              13.Indemnity

              <div>
                <br/>
                You shall indemnify and hold harmless Jenio and its directors, employees and agents,
                from and against any loss, liability and costs (including reasonable attorney fees, court costs and other
                litigation expenses) relating to any claim or demand made by any third party due to or arising out of your
                access to Services, violation of this Agreement, or infringement of any IPR or other right of any person or
                entity.
              </div>
            </div>

            <div>
              <br/>
              14.Warranty disclaimers

              <div>
                <br/>
                EXCEPT AS EXPRESSLY PROVIDED
                HEREIN, ALL SERVICES ARE PROVIDED “AS IS,” AND JENIO EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND
                REPRESENTATIONS OF ANY KIND, INCLUDING ANY WARRANTY OF NON-INFRINGEMENT, TITLE, FITNESS FOR A PARTICULAR
                PURPOSE, FUNCTIONALITY OR MERCHANTABILITY, WHETHER EXPRESS, IMPLIED OR STATUTORY. WITHOUT LIMITING OUR EXPRESS
                OBLIGATIONS IN THESE TERMS, JENIO DOES NOT WARRANT THAT YOUR USE OF SERVICES WILL BE UNINTERRUPTED OR
                ERROR-FREE, THAT JENIO WILL REVIEW YOUR DATA FOR ACCURACY OR THAT JENIO WILL PRESERVE OR MAINTAIN YOUR DATA
                WITHOUT LOSS. JENIO CANNOT GUARANTEE THAT OUR SECURITY PROCEDURES WILL BE ERROR-FREE, THAT TRANSMISSIONS OF
                YOUR DATA WILL ALWAYS BE SECURE OR THAT UNAUTHORIZED THIRD PARTIES WILL NEVER BE ABLE TO DEFEAT JENIO’S
                SECURITY MEASURES OR THOSE OF JENIO’S THIRD-PARTY SERVICE PROVIDERS. JENIO WILL NOT BE LIABLE FOR DELAYS,
                INTERRUPTIONS, SERVICE FAILURES OR OTHER PROBLEMS INHERENT IN USE OF THE INTERNET AND ELECTRONIC
                COMMUNICATIONS OR OTHER SYSTEMS OUTSIDE JENIO’S REASONABLE CONTROL.
              </div>
            </div>

            <div>
              <br/>
              15.Limitation of liability
              <div>
                <br/>
                EXCEPT FOR YOUR LIABILITY FOR PAYMENT OF FEES, YOUR LIABILITY ARISING FROM YOUR
                OBLIGATIONS UNDER THE ‘INDEMNIFICATION’ SECTION, AND YOUR LIABILITY FOR VIOLATION OF JENIO’S IPR, IF,
                NOTWITHSTANDING THE OTHER TERMS OF THIS AGREEMENT, EITHER PARTY IS DETERMINED TO HAVE ANY LIABILITY TO THE
                OTHER PARTY OR ANY THIRD PARTY, THE PARTIES AGREE THAT THE AGGREGATE LIABILITY OF A PARTY WILL BE LIMITED TO
                THE TOTAL AMOUNTS YOU HAVE ACTUALLY PAID FOR SERVICES IN THE TWELVE MONTH PERIOD PRECEDING THE EVENT GIVING
                RISE TO A CLAIM; PROVIDED HOWEVER, THIS LIMITATION SHALL NOT APPLY TO YOU IF YOU ONLY USE SERVICES ON TRIAL
                BASIS, AND IN THIS CASE, IF JENIO IS DETERMINED TO HAVE ANY LIABILITY TO YOU OR ANY THIRD PARTY ARISING FROM
                YOUR USE OF THE FREE SERVICES, THEN JENIO’S AGGREGATE LIABILITY WILL BE LIMITED TO ONE HUNDRED (100) USD.
              </div>
            </div>

            <div>
              <br/>
              16.Governing law
              <div>
                <br/>
                This Agreement will be governed by and
                construed in accordance with the laws of Japan, without regard to principles of conflicts of laws.
              </div>
            </div>

            <div>
              <br/>
              17.Jurisdiction
              <div>
                <br/>
                With respect to any disputes between you and
                Jenio arising from or in connection with Services or this Agreement, each party submits to the exclusive
                original jurisdiction of the Tokyo District Court of Japan.
              </div>
            </div>

            <div>
              18.General provisions

              <div>
                <br/>
                a.Force Majeure
                <br/> <br/>
                If Jenio is unable to perform any obligation under this Agreement because of any
                matter beyond its reasonable control (whether or not involving employees of Jenio) (“Force Majeure Event”),
                Jenio will have no liability to you for such failure to perform. If any Force Majeure Event continues for more
                than sixty (60) days, either Jenio or you may terminate this Agreement by delivery of written notice to the
                other party. You will remain responsible for all Fees incurred through the last day Services were
                available.
              </div>

              <div>
                <br/>
                b.No Assignment
                <br/><br/>
                You may not assign or otherwise transfer this
                Agreement or any of your rights or obligations hereunder, by operation of law or otherwise, without the prior
                written consent of Jenio. Jenio may freely assign this Agreement, including, without limitation, in connection
                with a merger, acquisition, bankruptcy, reorganization, or sale of some or all of our assets or stock.
              </div>

              <div>
                <br/>
                c.Severability
                <br/><br/>
                If one or more of the provisions of this
                Agreement is (are) for any reason held to be invalid, illegal or unenforceable by a court of competent
                jurisdiction, the remaining provisions of this Agreement shall be unimpaired and shall remain in full force
                and effect, and the invalid, illegal or unenforceable provision(s) shall be replaced by a valid, legal and
                enforceable provision or provisions that comes closest to the intent of the parties underlying the invalid,
                illegal or unenforceable provision(s).
              </div>

              <div>
                <br/>
                d.No implied waiver
                <br/><br/>
                The failure of either party to exercise in any respect any right provided for herein
                shall not be deemed a waiver of any further rights hereunder. A waiver by either party of any term or
                condition of this Agreement or any breach thereof, in any one instance, will not waive such term or condition
                or any subsequent breach thereof.
              </div>

              <div>
                <br/>
                e.Entire Agreement
                <br/><br/>
                In all other cases, this Agreement constitutes the entire agreement between you and
                Jenio with respect to its subject matter and supersedes all prior communications and proposals, whether
                electronic, oral or written, between Jenio and you. No waiver or modification of any of the provisions of this
                Agreement shall be binding unless in writing and signed by a duly authorized representative of each party.
              </div>
            </div>
          </div>
        </div>

        <LpFooter />
      </div>
    )
  }
}
