import { css, cx } from 'emotion';

export const lpHeaderCss = css`
  background: #fff;
  box-shadow: 0 1px 0 rgba(0,0,0,.2);
  // min-height: 54px;
  // padding: 8px 0;
  // height: 70px;
  
  .navbar-brand {
    color: #e63d3d;
    font-size: 29px;
    img {
      max-width: 100%;
      width: 60px;
    }
  }

  .logo-text{
      font-size: 35px;
      font-weight: bold;
  }
  
  .navbar-nav {
    padding: 10px 0;
    & > li {
      @media(min-width: 769px) {
        margin-left: 20px;
      }
      
      &.active {
        a {
          color: #e63d3d !important;
        }        
      } 
      
      & > a {
        color: #666;
        padding: 5px 0;
      }
    }
  }
`;
