import React from "react";
import {LpHeader} from "./landing-page/header/lp-header";
import {LpFooter} from "./landing-page/footer/lp-footer";
import {lpSignInCss, lpStyle} from "./landing-page/styles/main";
import GA from "../utils/GoogleAnalytics";
import sign_in_1 from '../img/sign_in_1.png';
import sign_in_2 from '../img/sign_in_2.png';
import paid_1 from '../img/paid_1.png';
import paid_2 from '../img/paid_2.png';
import paid_3 from '../img/paid_3.png';
import paid_4 from '../img/paid_4.png';

export class SignIn extends React.Component {

    constructor() {
        super();
        this.state = {
            loading: true,
            client_id: '',
        };
    }

    componentDidMount() {
        fetch('api/getSlackClientId')
            .then(res => res.json())
            .then(data => {
                if (data.client_id) {
                    this.setState({client_id: data.client_id});
                }
            })
            .catch(err => {
                console.log(err);
            })
    }


    render() {
        const {client_id} = this.state;
        const slack_url = "https://slack.com/oauth/v2/authorize?user_scope=identity.basic,identity.email,identity.team&client_id=" + client_id + "&state=production";
        const bypassOauth = "http://localhost:8081/api/oauth/bypass";
        const url = process.env.NODE_ENV === 'development' ? bypassOauth : slack_url;

        return (

            <div className={lpStyle}>
                <LpHeader/>
                <div className={lpSignInCss}>
                    <div className="container">
                        <div className="header">
                            How to Sign in
                        </div>
                        <div className="caution">
                            <b>※Currently we allow only 1 user per 1 workspace to access to sign in page.<br/>
                                So, we recommend that admin user of your workspace signs in at first.</b>
                        </div>
                        <div>
                            <p className="sub-header">1.Click↓</p>
                            <a href={url}
                               onClick={() => GA.Event('Authentication', 'Click Sign In', 'Sign in With Slack')}>
                                <img alt="Sign in with Slack" height="40" width="172"
                                     src="https://platform.slack-edge.com/img/sign_in_with_slack.png"
                                     srcSet="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x"/>
                            </a>
                        </div>
                        <div>
                            <p className="sub-header">2&3.Choose your workspace & Allow Kiara</p>
                            <img className="sign-in-img" src={sign_in_2}/>
                        </div>
                    </div>
                    <div className="container">
                        <div className="header">
                            Upgrade to Personal Plan<br/>
                            Paid Plan - $35/month
                        </div>
                        <div>
                            <p className="sub-header">1. Sign-in</p>
                            <img className="sign-in-img" src={paid_1}/>
                        </div>
                        <div>
                            <p className="sub-header">2. Allow Slack</p>
                            <img className="sign-in-img" src={paid_2}/>
                        </div>
                        <div>
                            <p className="sub-header">3. Put your credit card number</p>
                            <img className="sign-in-img" src={paid_3}/>
                        </div>
                        <div>
                            <p className="sub-header">4.You are now in paid "Personal" plan</p>
                            <img className="sign-in-img" src={paid_4}/>
                        </div>
                    </div>
                </div>
                <LpFooter/>
            </div>
        )
    }
}
