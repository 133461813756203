import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import {LandingPage} from './containers/landing-page/LandingPage';
import {Privacy} from './containers/Privacy';
import {Pricing} from './containers/Pricing';
import {Terms} from './containers/Terms';
import { SignIn } from './containers/SignIn';
import { Language } from './containers/Language';
import { Help } from './containers/Help';
import { Support } from './containers/Support';
import { RedirectOk } from './containers/RedirectOk';
import { RedirectNg } from './containers/RedirectNg';
import { Dashboard } from './containers/Dashboard';
import { AdminDashboard } from './containers/AdminDashboard';
import withAuth from './withAuth';
import withAdminAuth from './withAdminAuth';
import GA  from './utils/GoogleAnalytics';
import { SignOut } from './containers/SignOut';
import { Billing } from './containers/Billing';

function AppRouter() {
  
  return (
    <Router>
      {GA.init()}
        <Route exact path="/" component={GA.withTracker(LandingPage)}/>
        <Route exact path="/privacy-policy" component={GA.withTracker(Privacy)}/>
        <Route exact path="/pricing" component={GA.withTracker(Pricing)}/>
        <Route exact path="/terms" component={GA.withTracker(Terms)}/>
        <Route exact path="/sign-in" component={GA.withTracker(SignIn)}/>
        <Route exact path="/language" component={GA.withTracker(Language)}/>
        <Route exact path="/help" component={GA.withTracker(Help)}/>
        <Route exact path="/support" component={GA.withTracker(Support)}/>
        <Route exact path="/redirect-ok" component={GA.withTracker(RedirectOk)}/>
        <Route exact path="/redirect-ng" component={GA.withTracker(RedirectNg)}/>
        <Route exact path="/admin-dashboard" component={GA.withTracker(withAdminAuth(AdminDashboard))}/>
        <Route exact path="/dashboard" component={GA.withTracker(withAuth(Dashboard))}/>
        <Route exact path="/sign-out" component={GA.withTracker(withAuth(SignOut))}/>
        <Route exact path="/billing-history" component={GA.withTracker(withAuth(Billing))}/>
    </Router>
  );
  
}

export default AppRouter;
