import React from "react";
import {LpHeader} from "./landing-page/header/lp-header";
import {LpFooter} from "./landing-page/footer/lp-footer";
import {lpStyle, lpHelpCss, separatorContentCss} from "./landing-page/styles/main";
import description1 from "../img/description1.png";
import description2 from "../img/description2.png";

export class Help extends React.Component {
    render() {
        return (
            <div className={lpStyle}>
                <LpHeader/>
                <div className="container">
                    <div className={lpHelpCss}>
                        <div className="header">
                            <div className="main-text">FAQ</div>
                        </div>
                        <div className="s-subtitle">
                            <div className="s-component s-text">
                                <h4 className="s-component-content s-font-heading">
                                    <p className="big-font">
                                        <span className="s-text-color-red">
                                            <strong>Installation</strong>
                                        </span>
                                    </p>
                                    <ul>
                                        <li>
                                            <strong>How can I install Kiara?</strong>
                                        </li>
                                    </ul>
                                    <p>
                                        Click "Add to Slack" and install our app from&nbsp;
                                        <a href="https://www.kiara-app.com/">
                                            https://www.kiara-app.com
                                        </a>
                                    </p>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li>
                                            <strong>What should I do after "Add to Slack"?</strong>
                                        </li>
                                    </ul>
                                    <p>
                                        Click "Authorize" and invite Kiara to your channel by
                                        saying @kiara for real-time translation.
                                    </p>
                                    <p>Then, click
                                        "Add" and choose the language you want to translate.
                                    </p>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li>
                                            <strong>I have added Kiara to my Slack but I cannot find it.</strong>
                                        </li>
                                    </ul>
                                    <p>Say @kiara in your channel.</p>
                                    <p className="big-font">
                                        <span className="s-text-color-red">
                                            <strong>How to Use</strong>
                                        </span>
                                    </p>
                                    <ul>
                                        <li><strong>How can I add languages?​</strong></li>
                                    </ul>
                                    <p>Say @kiara and click "Add" and choose the language you want
                                        to add.</p><p>&nbsp;</p>
                                    <ul>
                                        <li><strong>How can I delete languages?​</strong></li>
                                    </ul>
                                    <p>Say @kiara and click "Delete" and choose the language you want to delete.</p>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li><strong>How can I delete Kiara?​</strong></li>
                                    </ul>
                                    <p>Click Kiara icon in your channel. Click "Setting". Click
                                        "Remove App" on the bottom of the page.</p><p>&nbsp;</p>
                                    <ul>
                                        <li><strong>I want to turn off the translation
                                            function.</strong></li>
                                    </ul>
                                    <p>Say @kiara, click "Setting" and turn off "Translation".</p>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li><strong>I want to turn on the translation
                                            function.</strong></li>
                                    </ul>
                                    <p>Say @kiara, click "Setting" and turn on "Translation".</p>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li><strong>Could I correct the translated
                                            result?</strong></li>
                                    </ul>
                                    <p>Sorry, you cannot.</p><p
                                >&nbsp;</p>
                                    <ul>
                                        <li><strong>Could we add my original translation via
                                            Kiara?</strong></li>
                                    </ul>
                                    <p>Sorry, you cannot.</p><p
                                >&nbsp;</p>
                                    <ul>
                                        <li><strong>I think the translation might be
                                            wrong...</strong></li>
                                    </ul>
                                    <p>Sorry. We are working to improve it.</p><p
                                >&nbsp;</p>
                                    <ul>
                                        <li><strong>What happen if the sentences contain more than
                                            one language?</strong></li>
                                    </ul>
                                    <p>The most appropriate language will be detected and
                                        reflected into the translated result.</p><p>&nbsp;</p>
                                    <ul>
                                        <li><strong>There are 3 languages set in my channel, but I
                                            need only one. </strong><strong>Could we change the setting as only one
                                            language
                                            translation will be shown to me?</strong></li>
                                    </ul>
                                    <p>Sorry, you cannot.</p><p
                                >&nbsp;</p>
                                    <ul>
                                        <li><strong>Could we use Kiara on mobile?</strong></li>
                                    </ul>
                                    <p>Yes. you can use Kiara on your mobile.</p><p
                                >&nbsp;</p><p className="big-font"><span
                                    className="s-text-color-red"><strong>Functions</strong></span></p>
                                    <ul>
                                        <li><strong>What is "Status"?​</strong></li>
                                    </ul>
                                    <p>Your current set languages and consumption left will be
                                        shown.</p><p>&nbsp;</p>
                                    <ul>
                                        <li><strong>What is "translation in post"?</strong></li>
                                    </ul>
                                    <p>Kiara shows the translation(s) as message in slack.</p><p
                                >&nbsp;</p>
                                    <ul>
                                        <li><strong>What is "translation in reply"?</strong></li>
                                    </ul>
                                    <p>Kiara show the translation(s) as reply (thread) in
                                        slack.</p><p>&nbsp;</p>
                                    <ul>
                                        <li><strong>What is "consumption"?</strong></li>
                                    </ul>
                                    <p>It shows the number of characters and the percentage you have used.</p><p
                                >&nbsp;</p>
                                    <ul>
                                        <li><strong>How many characters will be consumed when we set
                                            multi-languages?</strong></li>
                                    </ul>
                                    <p>The consumption volume will be increased depending on the number of setting
                                        languages (but input language will not be charged).</p>
                                    <p>
                                        example 1: Set English and Japanese<br/>
                                        Say “hello” with Kiara.
                                    </p>
                                    <p><img className="description-img" src={description1}/></p>
                                    <p>Translated into Japanese and 5 characters will be consumed.</p>
                                    <p>
                                        example 1: Set English, Japanese and Chinese<br/>
                                        Say “hello” with Kiara.
                                    </p>
                                    <p><img className="description-img" src={description2}/></p>
                                    <p>Translated into Japanese and Chinese and 10 characters will be consumed.</p>
                                    <ul>
                                        <li><strong>Do you have "translation memory" or
                                            "glossary"?</strong></li>
                                    </ul>
                                    <p>Sorry, we don't have.</p><p
                                >&nbsp;</p>
                                    <ul>
                                        <li><strong>Is "bold" reflected into the translated
                                            result?</strong></li>
                                    </ul>
                                    <p>Yes, it is reflected. (Some languages might not be,
                                        sorry).
                                    </p><p className="big-font"><span
                                    className="s-text-color-red"><strong>Tutorial Videos</strong></span></p>

                                    <div className="row">
                                        <div className="col-sm-9 col-xs-12">
                                            <iframe className="embedly-embed" title="tutorial-install-slack"
                                                    src="https://www.youtube.com/embed/Tn_k1S4Vdlc"
                                                    width="100%" height="360" scrolling="no"
                                                    frameBorder="0" allow="autoplay; fullscreen" allowFullScreen={true}>
                                            </iframe>
                                        </div>
                                        <div className="col-sm-3 video-description">
                                            <h4>
                                                How to install Kiara from Slack
                                            </h4>
                                        </div>
                                    </div>

                                    <div className={separatorContentCss}>
                                        <div className="separator"/>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-9 col-xs-12">
                                            <iframe className="embedly-embed" title="tutorial-install-web"
                                                    src="https://www.youtube.com/embed/woMavm802Mo"
                                                    width="100%" height="360" scrolling="no" frameBorder="0"
                                                    allow="autoplay; fullscreen" allowFullScreen={true}>
                                            </iframe>
                                        </div>
                                        <div className="col-sm-3 video-description">
                                            <h4>
                                                How to install Kiara from our Website
                                            </h4>
                                        </div>
                                    </div>

                                    <div className={separatorContentCss}>
                                        <div className="separator"/>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-9 col-xs-12">
                                            <iframe className="embedly-embed" title="add-language"
                                                    src="https://www.youtube.com/embed/e73OAEgg4WY"
                                                    width="100%" height="360" scrolling="no" frameBorder="0"
                                                    allow="autoplay; fullscreen" allowFullScreen={true}>
                                            </iframe>
                                        </div>
                                        <div className="col-sm-3 video-description">
                                            <h4>
                                                How to add language
                                            </h4>
                                        </div>
                                    </div>

                                    <div className={separatorContentCss}>
                                        <div className="separator"/>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-9 col-xs-12">
                                            <iframe className="embedly-embed" title="delete-language"
                                                    src="https://www.youtube.com/embed/qncq2I4aSOI"
                                                    width="100%" height="360" scrolling="no" frameBorder="0"
                                                    allow="autoplay; fullscreen" allowFullScreen={true}>
                                            </iframe>
                                        </div>
                                        <div className="col-sm-3 video-description">
                                            <h4>
                                                How to delete language
                                            </h4>
                                        </div>
                                    </div>

                                    <div className={separatorContentCss}>
                                        <div className="separator"/>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-9 col-xs-12">
                                            <iframe className="embedly-embed" title="turn-off-translation"
                                                    src="https://www.youtube.com/embed/EQiNd_MuyCw"
                                                    width="100%" height="360" scrolling="no" frameBorder="0"
                                                    allow="autoplay; fullscreen" allowFullScreen={true}>
                                            </iframe>
                                        </div>
                                        <div className="col-sm-3 video-description">
                                            <h4>
                                                How to turn off translation
                                            </h4>
                                        </div>
                                    </div>

                                    <div className={separatorContentCss}>
                                        <div className="separator"/>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-9 col-xs-12">
                                            <iframe className="embedly-embed" title="turn-on-translation"
                                                    src="https://www.youtube.com/embed/v-sH0Sj8Vwc"
                                                    width="100%" height="360" scrolling="no" frameBorder="0"
                                                    allow="autoplay; fullscreen" allowFullScreen={true}>
                                            </iframe>
                                        </div>
                                        <div className="col-sm-3 video-description">

                                            <h4>
                                                How to turn on translation
                                            </h4>
                                        </div>
                                    </div>

                                    <div className={separatorContentCss}>
                                        <div className="separator"/>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-9 col-xs-12">
                                            <iframe className="embedly-embed" title="translation-reply"
                                                    src="https://www.youtube.com/embed/qt9Te5J79ds"
                                                    width="100%" height="360" scrolling="no" frameBorder="0"
                                                    allow="autoplay; fullscreen" allowFullScreen={true}></iframe>
                                        </div>
                                        <div className="col-sm-3 video-description">
                                            <h4>
                                                What is translation in reply
                                            </h4>
                                        </div>
                                    </div>

                                    <div className={separatorContentCss}>
                                        <div className="separator"/>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-9 col-xs-12">

                                            <iframe className="embedly-embed" title="translation-post"
                                                    src="https://www.youtube.com/embed/KFHa2vgd4kk"
                                                    width="100%" height="360" scrolling="no" frameBorder="0"
                                                    allow="autoplay; fullscreen" allowFullScreen={true}></iframe>
                                        </div>
                                        <div className="col-sm-3 video-description">
                                            <h4>
                                                What is translation in post
                                            </h4>
                                        </div>
                                    </div>

                                    <div className={separatorContentCss}>
                                        <div className="separator"/>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-9 col-xs-12">
                                            <iframe className="embedly-embed" title="delete"
                                                    src="https://www.youtube.com/embed/6SX0gd88d58"
                                                    width="100%" height="360" scrolling="no" frameBorder="0"
                                                    allow="autoplay; fullscreen" allowFullScreen={true}></iframe>
                                        </div>
                                        <div className="col-sm-3 video-description">
                                            <h4>
                                                How to delete Kiara
                                            </h4>
                                        </div>
                                    </div>

                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <LpFooter/>
            </div>
        )
    }

}
