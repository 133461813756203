import React from "react";
import {css} from "emotion";
import { separatorContentCss } from "../styles/main";

let lpContentFrustrated = css`
  padding: 30px 0;

  & > .header {
    font-size: 20px;
    .main-text {
      font-size: 36px;
      color: #e63d3d;
    }
  }
  
  & > .list {
    .list-item {
      margin-top: 20px;
    
      .item-title {
        color: #e63d3d;
        font-size: 24px;
      }
      
      .one-hundred {
        font-size:24px;
      }
    }
  }
`;

export class LpContentFrustrated extends React.Component {
  render() {

    const list = [
      {
        title: "Supporting Languages",
        desc: (
          <span className="one-hundred">🇺🇸🇬🇧🇯🇵🇨🇳🇻🇳🇰🇷🇫🇷🇪🇸🇦🇷🇵🇹🇧🇷🇷🇺🇩🇪 + 100</span>
        )
      },
      {
        title: "Our own story : Japanese vs Vietnamese",
        desc: "We are a Japanese IT company. We develop our software in Vietnam. It was hard to understand each other and asked a translator's help. But now we use Kiara for communication. When we type in Japanese, VN team are able to understand it in Vietnamese. When they type in VN, we can see Japanese translation as real-time. This is magical and we love it a lot. This direct communication has created very strong team work!"
      },
      {
        title: "Kiara is a collaboration hub for an international team",
        desc: (
          <span>
            Business operation is becoming more international. You need to communicate with a foreign country more. A direct and real-time translation can definitely empower your international collaboration. <br/>
            Welcome to our work style innovation.</span>
        )
      },

    ];

    return (
      <div className={lpContentFrustrated}>
        <div className="header text-center">
          <div className="main-text">Frustrated by translation?</div>
          <div className="sub-text">Please use Kiara. It is very simple.</div>
        </div>

        <div className="list">
          {list.map((item, i) => (
            <div key={i} className="list-item">
              <div className="item-title">{item.title}</div>
              <div className="item-desc">{item.desc}</div>
            </div>
          ))}
        </div>

        <div className={separatorContentCss}><div className="separator"/></div>
      </div>
    )
  }
}
