import React from "react";
import { css } from "emotion";
import { separatorContentCss } from "../styles/main";

let lpContentBenefits = css`
  margin: 50px auto;
  
  & > .benefits-header {
    font-size: 20px;
    .main-text {
      font-size: 36px;
      color: #e63d3d;
    }
  }
  
  & > .benefits-list {
    margin-top: 20px;
    
    .benefits-list-item {
      margin-top: 20px;
      .item-title {
        color: #e63d3d;
        font-size: 24px;
      }
      
      .item-desc {
        font-weight: 700;
      }
    }
  }
`;

export class LpContentBenefits extends React.Component {
  render() {

    const list = [
      {
        icon: "https://assets.strikingly.com/static/icons/flat-modern/36.png",
        title: "Cost and time-saving",
        desc: "Real-time translation enables you to share info instantly"
      },
      {
        icon: "https://assets.strikingly.com/static/icons/flat-modern/21.png",
        title: "Collaboration improvement",
        desc: "We insanely improve the productivity of your international team"
      },
      {
        icon: "https://assets.strikingly.com/static/icons/flat-modern/91.png",
        title: "No language barriers!",
        desc: "Direct communication will build much stronger team work"
      },
    ];

    return (
      <div className={`${lpContentBenefits}`}>
        <div className="benefits-header text-center">
          <div className="main-text">Benefits</div>
          <div className="sub-text">We empower your business</div>
        </div>

        <div className="benefits-list">
          <div className="row">
            {list.map((item, i) => (
              <div key={i} className="benefits-list-item col-md text-center">
                <img src={item.icon} />
                <div className="item-title">{item.title}</div>
                <div className="item-desc">{item.desc}</div>
              </div>
            ))}
          </div>
        </div>

        <div className={separatorContentCss}><div className="separator" /></div>
      </div>
    )
  }
}
