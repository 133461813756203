import React from "react";
import {css} from "emotion";
import { separatorContentCss } from "../styles/main";
import fileAuthorizeGif from "../../../img/fileAuthorize.gif";
import fileChannelGif from "../../../img/fileHowTo.gif";

let lpContentHIWCss = css`
  padding: 30px 0;
  
  & > .header {
    font-size: 20px;
    .main-text {
      font-size: 36px;
      color: #e63d3d;
    }
  }
  
  & > .header-pricing {
    color: #e63d3d;
    font-size: 25px;
  }
  
  & > .list {
    .list-item {
      margin-top: 20px;
      img {
        &.gif {
          height: 200px;
          margin-bottom: 20px;
          width: 100%;
        }
        &.gif-add-to-slack {
          margin-bottom: 130px;
          width: 80%;
        }
      }
    
      .item-title {
        color: #e63d3d;
        font-size: 24px;
      }
      
      .item-desc {
        font-weight: 700;
      }
    }
  }
`;

let questionsButtonCss = css`
  padding: 25px 0;
  .question-buttons {
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    padding: 24px 44px;
    font-weight: 700;
    margin: 0 auto;
    padding: 24px 44px;
    text-decoration: none;
    text-shadow: 0 1px rgba(0,0,0,.2);
    background: #e63d3d;
    box-shadow: 0 2px 0 #c33434;
    font-size: 24px;
  }
`;

export class LpContentHIW extends React.Component {
  render() {

    const list = [
      {
        icon: "https://platform.slack-edge.com/img/add_to_slack.png",
        title: "1 - Click button",
        desc: "It takes 3 secs",
        otherDesc: `Click "Add to Slack" button to install Kiara to your Slack's workspace`
      },
      {
        icon: fileAuthorizeGif,
        title: "2 - Authorize Kiara",
        desc: "One-click approval",
        otherDesc: "Log in to your Slack account then authorize Kiara to your workspace"
      },
      {
        icon: fileChannelGif,
        title: "3 - Choose language",
        desc: "Easy to add",
        otherDesc: "Check your workspace in Slack"
      },
    ];

    const list1 = [
      {
        icon: "https://assets.strikingly.com/static/icons/flat-modern/19.png",
        title: "Free Plan",
        desc: "Up to 3,000 Characters",
        otherDesc: "Completely free to try!"
      },
      {
        icon: "https://assets.strikingly.com/static/icons/flat-modern/79.png",
        title: "Personal Plan",
        desc: "Unlimited use - $35/month",
        otherDesc: ""
      },
      {
        icon: "https://assets.strikingly.com/static/icons/flat-modern/73.png",
        title: "Premium Plan",
        desc: "Unlimited Use - $29/month per user",
        otherDesc: (
          <span>
            Coming soon <br/>
            With multiple AI features Variety of plugins
          </span>
        )
      },
    ];

    return (
      <div>
        <div className={lpContentHIWCss}>
          <div className="header text-center">
            <div className="main-text">How it works</div>
            <div className="sub-text">Very Easy 3 Steps</div>
          </div>

          <div className="list">
            <div className="row">
              {list.map((item, i) => (
                <div key={i} className="list-item col-md text-center">
                  <img className={item.title === '1 - Click button' ? 'gif-add-to-slack' : 'gif'} src={item.icon}/>
                  <div className="item-title">{item.title}</div>
                  <div className="item-desc">{item.desc}</div>
                  <div className="item-other-desc">{item.otherDesc}</div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className={separatorContentCss}><div className="separator"/></div>

        <div className={`${questionsButtonCss} text-center`}>
          <a className="question-buttons" href="mailto:hello@kiara-app.com"
             data-component="button" target="_blank">Question? Feel free to ask us!</a>
        </div>

      </div>
    )
  }
}
