import React from "react";
import {LpContentHeader} from "./lp-content-header";
import {LpContentBenefits} from "./lp-content-benefits";
import {LpContentHIW} from "./lp-content-hiw";
import {LpContentFrustrated} from "./lp-content-frustrated";
import {LpContentHappyCustomer} from "./lp-content-happy-customer";
import { LpContentAccelerators } from "./lp-content-accelerators";

export class LpContent extends React.Component {
  render() {
    return (
      <div>
        <LpContentHeader/>
        <div className="container">
          <LpContentBenefits/>
          <LpContentHIW/>
          <LpContentFrustrated/>
          <LpContentHappyCustomer/>
          <LpContentAccelerators/>
        </div>
      </div>
    )
  }
}
