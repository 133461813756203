import React, { Component, } from "react";
import GoogleAnalytics from "react-ga";


const init = () => {
    GoogleAnalytics.initialize(process.env.REACT_APP_GA_TRACKING_ID);
}

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    GoogleAnalytics.set({
      page,
      ...options,
    });
    GoogleAnalytics.pageview(page);
  };

  // eslint-disable-next-line
  const HOC = class extends Component {
    componentDidMount() {
      // eslint-disable-next-line
      const page = this.props.location.pathname + this.props.location.search;
      trackPage(page);
    }

    componentDidUpdate(prevProps) {
      const currentPage =
        prevProps.location.pathname + prevProps.location.search;
      const nextPage =
        this.props.location.pathname + this.props.location.search;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

/**
 * Event - Add custom tracking event.
 * @param {string} category 
 * @param {string} action 
 * @param {string} label 
 */
const Event = (category, action, label) => {
  console.log('Click', category, action, label);
  GoogleAnalytics.event({
      category: category,
      action: action,
      label: label
    });
  };

export default {
    init,
    withTracker,
    Event
}
