import React from "react";
import { css } from "emotion";
import { LpFooter } from "./landing-page/footer/lp-footer";
import { LpHeader } from "./landing-page/header/lp-header";
import { lpStyle, separatorContentCss } from "./landing-page/styles/main";

let PricingPage = css`

.navbar{
    display: flex;
    justify-content: space-between;
    background-color: #F8F9FA;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 0px;
}

.nav-hamburger{
    margin: 30px;
    border: 1px solid grey;
    padding: 12px;
}

.nav-items{
    display: none;
}

.logo-container{
    display: flex;
}

.logo-container img{
    width: 70px;
    text-align: left;
    margin: 10px;
}

.logo-container p {
    margin: 20px 0px;
    font-size: 37px;
}

img{
    max-width: 100%;
}

a{
    text-decoration: none;
}

.heading h1{
    margin-bottom: 8px;
}

.heading span{
    font-size: 25px;
}

.heading{
    text-align: center;
    margin: 25px 10px 60px 10px;
    font-size: 20px;
    line-height: 1.2;
    margin-top: 150px;
}

.pricing-switch{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-bottom: 30px;
}

.pricing-switch > button{
    border: 2px solid #E63D3D;
    width: 100px;
    padding: 8px;
    text-align: center;
    cursor: pointer;
    font-size: 18px;
    outline: none;
}

.pricing-cards{
    display: none;
}

.pricing-cards.show{
    display: block;
}

.pricing-btn-style{
    color: #FFF;
    background-color: #E63D3D;
    border: 1px solid #E63D3D;
}

.pricing-card{
    background-color: #F8F9FA;
    box-shadow: 2px 2px 2px 2 rgba(0, 0, 0, 0.2);
    margin: 20px 20px 50px 20px;
    text-align: center;
    border: 1px solid #E63D3D;
}

.pricing-card-header{
    height: 150px;
    border: 1px solid #E63D3D;
    background-color: #E63D3D;
    color: #FFF;
}

.pricing-card-header > h2{
  margin-top: 20px;
}

.price-tag{
    font-size: 40px;
    margin: 10px;
}

.price-tag > span{
    font-size: 25px;
    margin-right: 5px;
}

.pricing-card > ul{
    height: 300px;
    font-size: 18px;
    line-height: 30px;
    font-family: 'Josefin Sans', sans-serif;
    margin: 20px 10px;
    text-align: left;
    padding: 10px 15px;

}

.pricing-card > ul > li{
    margin-bottom: 10px;
    list-style-type: none;
}

.fa-check{
    margin-right: 10px;
    color: #00000080;
}

.pricing-btn{
    margin: 0px 20px 20px 20px;
    padding: 10px;
    border-radius: 0;
    background-color: #E63D3D;
    color: #FFF;
    outline: none;
    border: 1px solid #E63D3D;
    font-size: 18px;
}

.pricing-btn-link{
  color: #FFF;
}

.pricing-btn-link:hover{
  color: #FFF;
}

.h2{
  margin-top: 20px;
}

@media screen and (min-width: 900px) {
    .navbar{
        justify-content: space-around;
    }
    
    .nav-hamburger{
        display: none;
    }

    .nav-items{
        display: block;
        color: #00000080;
    }

    .nav-items > ul{
        display: flex;
        justify-content: space-around;
        margin: 40px 20px 10px 20px;
        font-size: 18px;
    }

    .nav-items > ul > li{
        list-style-type: none;
        margin-right: 30px;
    }

    a:hover{
        color: #E63D3D;
    }

    
    .pricing-cards{
        padding: 0px 130px;
    }
    
    .pricing-card{
        flex: 1;
    }

    .monthly-pricing-cards{
        display: none;
    }

    .pricing-cards.show{
        display: flex;
    }
}
`;

export class Pricing extends React.Component {
  constructor() {
    super();
    this.state = { isAnnual: true };
    this.handleAnnualClick = this.handleAnnualClick.bind(this);
    this.handleMonthlyClick = this.handleMonthlyClick.bind(this);
  };

  handleAnnualClick() {
    this.setState({ isAnnual: true })
  }
  handleMonthlyClick() {
    this.setState({ isAnnual: false })
  }

  render() {
    return (
      <div className={lpStyle}>
        <LpHeader />
        <div className={`${PricingPage}`}>
          <div className="heading">
            <h1>Kiara is 95% cheaper than a human translator!</h1>
            <span>Kiara for slack</span>
          </div>
          <div className="pricing-switch">
            <button className={`${this.state.isAnnual ? 'pricing-btn-style' : ''}`} id="annual-btn" onClick={this.handleAnnualClick}>Annual</button>
            <button className={`${this.state.isAnnual ? '' : 'pricing-btn-style'}`} id="monthly-btn" onClick={this.handleMonthlyClick}>Monthly</button>
          </div>
          <div className={`pricing-cards annual-pricing-cards ${this.state.isAnnual ? 'show' : ''}`}>
            <div className="pricing-card">
              <div className="pricing-card-header">
                <h2>Free</h2>
                <div>
                  <p className="price-tag"><span>$</span>0</p>
                </div>
              </div>
              <ul>
                <li><i className="fas fa-check"></i>Free annual plan is currently unavailable</li>
              </ul>
              <button className="pricing-btn"><a href="/" className="pricing-btn-link">No free annual plan</a></button>
            </div>
            <div className="pricing-card">
              <div className="pricing-card-header">
                <h2>Personal</h2>
                <div>
                  <p className="price-tag"><span>$</span>470</p>
                </div>
              </div>
              <ul>
                <li><i className="fas fa-check"></i>Up to 12million characters per year</li>
                <li><i className="fas fa-check"></i>Don't worry! 99% of Kiara users can not use up 12 million in a year</li>
                <li><i className="fas fa-check"></i>12 million is 12,000 pages of an average paper back book.</li>
                <li><i className="fas fa-check"></i>年間最大1200万文字$470。12,000ページの文庫本。</li>
              </ul>
              <button className="pricing-btn"><a href="/sign-in" className="pricing-btn-link">Get started</a></button>
            </div>
            <div className="pricing-card">
              <div className="pricing-card-header">
                <h2>Business</h2>
                <div>
                  <p className="price-tag"><span></span>Coming soon</p>
                </div>
              </div>
              <ul>
                <li><i className="fas fa-check"></i>Coming soon</li>
                <li><i className="fas fa-check"></i>Unlimited usage</li>
                <li><i className="fas fa-check"></i>Analysis features</li>
                <li><i className="fas fa-check"></i>使用制限のないプランを作成中です。</li>
              </ul>
              <button className="pricing-btn"><a href="mailto:hello@kiara-app.com" className="pricing-btn-link">Contact Us</a></button>
            </div>
          </div>
          <div className={`pricing-cards monthly-pricing-cards ${this.state.isAnnual ? '' : 'show'}`}>
            <div className="pricing-card">
              <div className="pricing-card-header">
                <h2>Free</h2>
                <div><p className="price-tag"><span>$</span>0</p></div>
              </div>
              <ul>
                <li><i className="fas fa-check"></i>Completely free to try</li>
                <li><i className="fas fa-check"></i>Up to 10,000 character transalation</li>
                <li><i className="fas fa-check"></i>An average of 5 people will consume in 2 months</li>
                <li><i className="fas fa-check"></i>1万文字まで完全無料. 5人のチームなら平均2ヶ月使えます.</li>
              </ul>
              <button className="pricing-btn"><a href="/sign-in" className="pricing-btn-link">Get started</a></button>
            </div>
            <div className="pricing-card">
              <div className="pricing-card-header">
                <h2>Personal</h2>
                <div>
                  <p className="price-tag"><span>$</span>49</p>
                </div>
              </div>
              <ul>
                <li><i className="fas fa-check"></i>Up to 1million characters per month</li>
                <li><i className="fas fa-check"></i>Don't worry! 99% of Kiara users can not use up 1 million</li>
                <li><i className="fas fa-check"></i>1 million is 1,000 pages of an average paper back book.</li>
                <li><i className="fas fa-check"></i>100万文字まで月$35. 文庫本1,000ページ分.</li>
              </ul>
              <button className="pricing-btn"><a href="/sign-in" className="pricing-btn-link">Get started</a></button>
            </div>
            <div className="pricing-card">
              <div className="pricing-card-header">
                <h2>Business</h2>
                <div>
                  <p className="price-tag"><span></span>Coming soon</p>
                </div>
              </div>
              <ul>
                <li><i className="fas fa-check"></i>Coming soon</li>
                <li><i className="fas fa-check"></i>Unlimited usage</li>
                <li><i className="fas fa-check"></i>Analysis features</li>
                <li><i className="fas fa-check"></i>使用制限なしプラン開発中.</li>
              </ul>
              <button className="pricing-btn"><a href="mailto:hello@kiara-app.com" className="pricing-btn-link">Contact Us</a></button>
            </div>
          </div>
          <div className={separatorContentCss}><div className="separator" /></div>
        </div>
        <LpFooter />
      </div>
    )
  }
}
