import React from "react";
import { LpHeader } from "./landing-page/header/lp-header";
import { LpFooter } from "./landing-page/footer/lp-footer";
import { lpStyle, lpRedirect } from "./landing-page/styles/main";

export class RedirectNg extends React.Component {
  render() {
    return (
      <div className={lpStyle}>
        <LpHeader />
        
        <div className="container">
          <div className={lpRedirect}>
              <div className="header">
                  Failed...
                  <br/>
                  <br/>
              </div>

              <div>
                  We are very sorry that there was an error installing Kiara to your workspace.
                  <br/>
                  (Please check if you type [add to slack button] at first)
                  <br/>
                  <br/>
                  Please try again.
              </div>
          </div>
        </div>

        <LpFooter />
      </div>
    )
  }
}
