import React from "react";
import {Header} from "./dashboard/Header";
import {DashboardLayoutCss, DashboardMainCss} from "./dashboard/styles/main";
import {LpFooter} from "./landing-page/footer/lp-footer";
import {lpStyle, lpPrivacyCss} from "./landing-page/styles/main";
import {Elements, StripeProvider} from 'react-stripe-elements';
import CheckoutForm from "../components/CheckoutForm";
import GA from '../utils/GoogleAnalytics';

export class Dashboard extends React.Component {

    constructor() {
        super();
        this.state = {
            stripe: null,
            plan: "",
            completePayment: false,
            completeUpdateCard: false,
            editCard: false,
            stripeEmail: '',
            nextPaymentDate: '',
            alreadyFreePlan: '',
            card: {}
        };
        this.getAccount = this.getAccount.bind(this);
        this.setCompletePayment = this.setCompletePayment.bind(this);
        this.setCompleteUpdateCard = this.setCompleteUpdateCard.bind(this);
    }

    componentDidMount() {
        fetch("/api/getStripeKey")
            .then(res => res.json())
            .then(data => {
                if (data.key) {
                    const stripeJs = document.createElement('script');
                    stripeJs.src = 'https://js.stripe.com/v3/';
                    stripeJs.async = true;
                    stripeJs.onload = () => {
                        // The setTimeout lets us pretend that Stripe.js took a long time to load
                        // Take it out of your production code!
                        setTimeout(() => {
                            this.setState({
                                stripe: window.Stripe(data.key),
                            });
                        }, 500);
                    }
                    document.body && document.body.appendChild(stripeJs);
                }
            });

        this.getAccount();
    }

    setCompletePayment() {
        this.setState({
            completePayment: true
        })
    }

    setCompleteUpdateCard() {
        this.setState({
            completeUpdateCard: true,
            editCard: false
        })
    }

    getAccount() {
        fetch('/api/account')
            .then(res => res.json())
            .then(data => {
                if (data.plan) {
                    this.setState({plan: data.plan});
                    this.setState({card: data.card});
                    this.setState({stripeEmail: data.stripeEmail});
                    this.setState({nextPaymentDate: data.nextPaymentDate});
                    this.setState({alreadyFreePlan: data.alreadyFreePlan});
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    submit = event => {
        event.preventDefault();
        const confirm = window.confirm("Are you sure want to change to the free plan?");
        if (confirm) {
            GA.Event('Dashboard', 'Change to free plan', 'Payment');
            fetch("/api/plan/change", {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'plan': 0
                })
            })
                .then(() => {
                    fetch('/api/account')
                        .then(res => res.json())
                        .then(data => {
                            if (data.plan) {
                                this.setState({plan: data.plan, alreadyFreePlan: data.alreadyFreePlan});
                            }
                        })
                        .catch(err => {
                            console.log(err);
                        })
                })
                .catch()
        }
    };

    updateCreditCard = event => {
        event.preventDefault();
        this.setState({editCard: true});
    }

    render() {
        const {plan, completePayment, card, editCard, completeUpdateCard, stripeEmail, nextPaymentDate, alreadyFreePlan} = this.state;
        return (
            <div className={lpStyle + ' ' + DashboardMainCss + ' ' + DashboardLayoutCss}>
                <Header/>

                <div className="container form-container">
                    <div className="css-kxp0xy">
                        <div className="example">
                            <div className="current_plan">
                                <div className="left">
                                    <span>Current Plan</span>
                                </div>
                                <div className="right">
                                    <span>{plan}</span>
                                </div>
                            </div>

                            {
                                (plan === 'Free') ? (

                                    <div className="current_plan">
                                        <div className="left">
                                            <span>
                                                
                                                    Total
                                            </span>
                                        </div>
                                        <div className="right">
                                            <span>$35/month</span>
                                        </div>
                                        <div className="left">
                                            <span>
                                                    *Up to 1 million character translation.<br/> Don't worry! 99% of Kiara users can not use up 1 million.
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <p></p>
                                )
                            }

                            {completePayment ?
                                <div className="current_info">
                                    <span>Purchase completed!</span>
                                </div> : <br/>
                            }

                            {completeUpdateCard ?
                                <div className="current_info">
                                    <span>Credit card is updated!</span>
                                </div> : <br/>
                            }


                            <StripeProvider stripe={this.state.stripe}>
                                <div>
                                    {(plan === 'Free' ? (
                                        <div className="form-content">
                                            <div className="header">
                                                <h2>Kiara Plan Upgrade Form</h2>
                                                <p className="formDescription break-word">Thank you very much for
                                                    upgrading!</p>
                                                <p className="formDescription">Please fill the following form</p>
                                            </div>


                                            <div className="form-content-inner">
                                                <Elements>
                                                    <CheckoutForm
                                                        getAccount={this.getAccount}
                                                        setCompletePayment={this.setCompletePayment}
                                                    />
                                                </Elements>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="form-content">
                                            <div className="header">
                                                <h2>
                                                    Payment Method
                                                </h2>
                                            </div>


                                            <div className="form-content-inner">
                                                <div className='Stripe'>
                                                    <label>
                                                        Credit Card Details
                                                    </label>


                                                    {(card && editCard ? (
                                                        <div className="StipeElement">

                                                            <p>
                                                                {card.brand + ' ************' + card.last4 + ' ' + card.exp_month + '/' + card.exp_year}
                                                            </p>
                                                            <p>
                                                                {'Email: ' + stripeEmail}
                                                            </p>
                                                            <Elements>
                                                                <CheckoutForm
                                                                    getAccount={this.getAccount}
                                                                    setCompletePayment={this.setCompleteUpdateCard}
                                                                    isPay={false}/>
                                                            </Elements>
                                                        </div>
                                                    ) : (
                                                        <div className="StipeElement">
                                                            {card.brand + '************' + card.last4 + ' ' + card.exp_month + '/' + card.exp_year}
                                                            <br/>
                                                            {'Email: ' + stripeEmail}
                                                            <br/>
                                                            {(alreadyFreePlan == true ? (
                                                                <p>
                                                                    {"You've already changed to free plan"}<br/>
                                                                    {'Your paid plan is valid until: ' + nextPaymentDate}
                                                                </p>
                                                            ) : (
                                                                <div>
                                                                    {'Your next monthly payment date is scheduled for: ' + nextPaymentDate}
                                                                    <div className="form-btn">
                                                                        <button className="btn btn-default"
                                                                                onClick={this.updateCreditCard}>Update
                                                                            Payment Details
                                                                        </button>
                                                                    </div>
                                                                    <br/>
                                                                    <div className="form-btn">
                                                                        <button className="btn btn-default"
                                                                                onClick={this.submit}>Change to
                                                                            Free plan
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    }
                                </div>
                            </StripeProvider>
                            <br/>
                        </div>
                    </div>
                </div>


                <LpFooter/>
            </div>
        )
    }
}
