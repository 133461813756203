import { css } from "emotion";

export const lpStyle = css`
    font-family: 'Lato', sans-serif;
    margin-top: 70px;
`;

export const lpPrivacyCss = css`
    padding: 30px 0;

    .header {
        color: #e63d3d;
        font-size: 30px;
    }
    a {
    color: #e63d3d;
    }
`;


export const lpRedirect = css`
   padding: 30px 0;
   text-align: center;
   
   .header {
    color: #e63d3d;
    font-size: 30px;
   }
   a {
    color: #e63d3d;
   }
`;

export const lpLanguageCss = css`
   padding: 30px 0;
   text-align: center;
   
   a {
    color: #e63d3d;
   }
`;

export const lpSignInCss = css`
   padding: 30px 0;
   text-align: center;
   background-color: rgb(249,163,208);
   background-color: rgba(249,163,208);
   color:white;
   
   .header {
    font-size: 30px;
   }
   
   .caution {
    color: #222;
   }
   
   .sub-header {
    font-size: 25px;
    margin: 30px 0px;
   }
   
   .sign-in-img {
    width: 1000px;
   }
   
   .sub-header2 {
    font-size: 35px;
    margin: 30px 0px;
   }
   
   .container {
     margin-bottom: 100px;
   }
`;

export const lpHelpCss = css`
   padding: 30px 0;
   text-align: center;
   
   .header {
        color: #e63d3d;
        font-size: 30px;
   }
    
   a {
    color: #e63d3d;
   }
   
   p {
    font-size: 17px;
    text-align: left;
   }
   
   li {
    text-align: left;
   }
   
   strong {
    font-size: 16px;
    font-weight:900;
   }
   
   .big-font {
    text-align: center;
    color: #e63d3d;
    font-weight:900;
   }
   
   .s-text-color-red strong{
    font-size: 25px;
    font-weight:bold;
   }
   
   .description-img {
    width: 300px;
   }
   
   .video-description {
    color: #e63d3d;
   }
`;

export const lpSupportCss = css`
   padding: 30px 0;
   text-align: center;
   
   .header {
        color: #e63d3d;
        font-size: 30px;
   }

   a {
    color: #e63d3d;
   }

   .contact-input {
    width: 450px;
    -webkit-appearance: none;
    background: #fff;
    border: 1px solid #bbb;
    border-radius: 3px;
    color: #444;
    font-size: 18px;
    padding: 11px 10px;
   }
   
   .email-button {
    background-color: #e63d3d;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
    margin: 0 auto;
    padding: 13px 34px 15px;
    text-decoration: none;
    text-shadow: 0 1px rgba(0,0,0,.2);
    zoom: 1;
   }
   
`;

export const lpContentPricingCss = css`
  padding: 30px 0;
  
  & > .header {
    font-size: 20px;
    .main-text {
      font-size: 36px;
      color: #e63d3d;
    }
  }
  
  & > .header-pricing {
    color: #e63d3d;
    font-size: 25px;
  }
  
  & > .list {
    .list-item {
      margin-top: 20px;
      img {
         height: 260px;
         margin-bottom: 20px;
         width: 100%;
      }
    
      .item-title {
        color: #e63d3d;
        font-size: 24px;
      }
      
      .item-desc {
        margin-top: 50px;
        font-weight: 700;
        a {
           color: #e63d3d;
        }
      }
    }
  }
  
  .price-image {
    width:  277px !important;
    height: 278px !important;
  }
  
  .update-personal {
    background: #e63d3d;
    box-shadow: 0 2px 0 #c33434;
    font-size: 23px;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    margin: 0 auto;
    padding: 13px 35px 15px;
    text-decoration: none;
    text-shadow: 0 1px rgba(0,0,0,.2);
    zoom: 1;
    margin-top:23px;
  }
`;


export const separatorContentCss = css`
  position: relative;
  .separator {
    background: #ddd;
    border-radius: 2px;
    height: 3px;
    margin: 30px auto;
    width: 100%;
  }
`;

