import React from "react";
import { Redirect } from 'react-router-dom';

export class SignOut extends React.Component {
    constructor() {
        super();
        this.state = {
          loading: true,
          redirect: false,
        };
      }

      componentDidMount() {
        fetch('/api/account/sign-out')
          .then(res => {
            if (res.status === 200) {
              this.setState({ loading: false, redirect: true });
            } else {
              const error = new Error(res.error);
              throw error;
            }
          })
          .catch(err => {
            console.error(err);
            this.setState({ loading: false, redirect: true });
          });
      }

      render() {
          const { loading, redirect } = this.state;
          if (loading) {
            return null;
          }

          if (redirect) {
              return <Redirect to="/" />
          }
      }
}