import React from "react";
import { LpHeader } from "./landing-page/header/lp-header";
import { LpFooter } from "./landing-page/footer/lp-footer";
import { lpStyle, lpRedirect } from "./landing-page/styles/main";

export class RedirectOk extends React.Component {
  render() {
    return (

      <div className={lpStyle}>
        <LpHeader />
        
        <div className="container">
          <div className={lpRedirect}>
              <div className="header">
                  Success!
                  <br/>
                  <br/>
              </div>

              <div className="">
                  <strong>
                      Please invite 「Kiara Translation」bot to channel where you can translate!
                  </strong>
              </div>
          </div>
        </div>

        <LpFooter />
      </div>
    )
  }
}
