import React from "react";
import {DashboardHeaderCss} from "./styles/main";
import logo from '../../img/logo.png'

export class Header extends React.Component {
  render() {
    let pathname = window.location.pathname;
    let match = (path) => pathname.substring(pathname.indexOf('/')).indexOf(path) > -1;

    return (
      <nav className={`${DashboardHeaderCss} navbar navbar-expand-md fixed-top navbar-light bg-light`}>
        <div className="container">
          <a className="navbar-brand" href="#">
            <img alt="kiara-logo" src={logo} />
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"/>
          </button>

          <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
            <ul className="navbar-nav">
              {/* <li className={`nav-item ${match("/dashboard") ? "active" : ""}`}>
                <a className="nav-link" href="/dashboard">Dashboard</a>
              </li> */}
              <li className={`nav-item ${pathname === "/" ? "active" : ""}`}>
                <a className="nav-link" href="/sign-out">Sign Out</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}
