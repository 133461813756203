import React from "react";
import {LpHeader} from "./header/lp-header";
import {LpContent} from "./content/lp-content";
import {LpFooter} from "./footer/lp-footer";
import { lpStyle } from "./styles/main";

export class LandingPage extends React.Component {
  render() {
    return (

      <div className={lpStyle}>
        <LpHeader />
        <LpContent/>
        <LpFooter />
      </div>
    )
  }
}
