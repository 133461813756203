import React from "react";
import { LpFooter } from "./landing-page/footer/lp-footer";
import { LpHeader } from "./landing-page/header/lp-header";
import { lpStyle, lpPrivacyCss } from "./landing-page/styles/main";

export class Privacy extends React.Component {
  render() {
    return (
      <div className={lpStyle}>
        <LpHeader />
        
        <div className="container">
          <div className={lpPrivacyCss}>
            <div>
              <div className="header">Privacy Policy</div>
              <div className="date">Effective: April 10, 2019</div>
            </div>

            <div>
              This Privacy Policy (“Policy”) describes how Jenio Inc. (“Jenio”) collects, uses and discloses information, and what choices you have with respect to the information. <br/>
              Capitalized terms used in this Policy and not otherwise defined shall have the meanings provided for those terms in <a target="_blank" href="https://kiara-app.com/terms">Kiara’s Terms of Service</a> (“Terms of Service”), which incorporates this Policy.
            </div>

            <div>
              <br/>
              1.What is the scope of this Privacy Policy?
              <br/><br/>
              This Policy applies to the information obtained by through your use of “Kiara”, a multi-language translation bot service provided on Slack® including <a target="_blank" href="https://www.kiara-app.com/">Kiara website</a> and other Jenio’s websites (collectively, “Services”) and other interactions (e.g., customer service inquiries, etc.) you may have with Jenio.
            </div>

            <div>
              <br/>
              2. Information collection and use
              <br/>
              <br/>
              Jenio collects Information that could be used to identify you (“Personal Data”) and other information listed below (collectively, but excluding your credit number, “User Data”).

              <div>
                <br/>
                a.What Information does Jenio collect about me?
                <br/>
              </div>

              <div>
                i.Personal Data you provide for signup
                <br/>
                When you sign up for or use our Services, you voluntarily give us certain Personal Data, including your email, name, password you choose for Kiara and Slack®’s Team ID to be associated with your account.
              </div>

              <div>
                <br/>
                ii.Data collected via Slack® API
                <br/>
                We collect this Information as you use Services, including:
                <br/>
                <ul>
                  <li>
                    User Content. This consists of all text uploaded, entered, or otherwise transmitted by you and other users who are in the Slack®’s workplace where you invite Kiara bot in connection with your use of Services (“User Content”).
                  </li>
                  <li>
                    Other information collected via Slack® API. In addition to User content above, Jenio collects Slack®’s Team ID, user ID, and timestamp of each post via Slack® API.
                  </li>
                </ul>
              </div>

              <div>
                iii.Your payment information
                <br/>
                If you subscribe Services as paying customer, you give your credit card number directly to Stripe®. <a target="_blank" href="https://stripe.com/us/privacy">The privacy policy of Stripe</a>® will apply to the information you provide on the Stripe® widget.
              </div>

              <div>
                <br/>
                b.How does Jenio use my Information?
                <br/>
                Jenio uses, processes and stores User Data as necessary to perform our contract with you and for our legitimate business purposes and interests, including:
              </div>

              <div>
                <br/>
                i.Provide automatic translations on Slack®
                <br/>
                Jenio uses User Data to deliver Services, including to display User Content associated with the Slack®’s Room you designate in your signup.
              </div>

              <div>
                <br/>
                ii.Process Payment (if applicable)
                <br/>
                <a target="_blank" href="https://stripe.com/">Stripe</a>® uses your payment information to process transactions associated with your subscription of Services.
              </div>

              <div>
                <br/>
                iii.Communicate with you
                <br/>
                Jenio uses User Data for signup to interact with you directly. Jenio may let you know about upcoming changes or improvements to Services, as well as respond to your requests for assistance.
              </div>


              <div>
                iv.Distribute marketing information (if applicable)
                <br/>
                Jenio sends you direct marketing emails and special offers about Kiara and other Jenio’s Services, from which you can unsubscribe at any time. If you are located in the European Economic Area (“EEA”), Jenio will only send you marketing information if you consent to us doing so at the time you create your account or any point thereafter.
              </div>

              <div>
                <br/>
                v.Improving Jenio’s products
                <br/>
                Jenio anonymize your information and use it on an anonymous basis to make improvements to Services.
              </div>

              <div>
                <br/>
                c.Does Jenio review User Content?
                <br/>
                Jenio’s employees do not monitor or view your User Content stored in or transferred through Services unless Jenio needs to respond to your requests for support. Jenio otherwise determines that Jenio have an obligation to review it as described in the Terms of Service, to protect the rights, property, or personal safety of Services and its users, or to comply with legal obligations.
              </div>
            </div>


            <div>
              <br/>
              3.Information access and disclosure
              <div>
                <br/>
                a.Does Jenio share my Information?
                <br/>
                Jenio will obtain your explicit consent to share your Personal Data with the third party unless Jenio needs to use service providers to get assistance for business operations, including hosting, delivering, and improving Services. These service providers may only access, process, or store Personal Data pursuant to Jenio’s instructions and may not use Personal Data for any other purposes.
                <br/>
                Jenio may share non-Personal Data publicly and with third parties – for example, Jenio uses Google Cloud Translation API for sophisticating Services and share User Content with Google on an anonymous basis. For information about Google’s data usage of Cloud Translation API, please see <a target="_self" href="https://cloud.google.com/translate/data-usage">https://cloud.google.com/translate/data-usage</a>.
              </div>


              <div>
                <br/>
                b.Does Jenio sell my Personal Data?
                <br/>
                Jenio never your Personal Data.
              </div>

              <div>
                <br/>
                c.Will Jenio send me messages?
                <br/>
                Jenio may contact you with direct marketing messages such as product announcements, software updates, and special offers. You may opt out of such messages at any time by clicking the “unsubscribe” link found within Jenio’s messages and changing your contact preferences. You will continue to receive transactional messages related to Services, even if you unsubscribe from marketing messages.
                <br/>
                For EEA users: Jenio only sends marketing messages to users located in the EEA with your prior consent. Please see the section “For EEA users” below.
              </div>

              <div>
                <br/>
                d.Does Jenio collect information from children?
                <br/>
                To the extent prohibited by applicable laws and regulations, Jenio does not allow the use of Services by anyone younger than 16 years old. If Jenio acknowledges anyone younger than 16 has unlawfully provided Jenio with Personal Data, Jenio will take steps to delete such Personal Data.
              </div>
            </div>

            <div>
              <br/>
              4.Data storage, transfer, retention, and deletion

              <div>
                <br/>
                a.Where is my Information stored?
                <br/>
                User Data collected in the course of Services will be transferred to, processed, and stored in Japan. When you use Slack® on your device, User Content and its translations posted by Services will be stored locally on the said device and by Slack® in accordance with Slack® privacy policy.
              </div>

              <div>
                <br/>
                b.How my Information is secured?
                <br/>
                Jenio takes reasonable measures to keep your information secured. Jenio uses industry-standard encryption to protect your information in transit.
                <br/>
                However, please note that no one can guarantee 100% safety, and you acknowledge that you need to protect your information at your own risk.
              </div>

              <div>
                <br/>
                c.How can I delete my Personal Data from Jenio?
                <br/>
                You can remove your Personal Data from Jenio by contacting and requesting Jenio to remove it.
              </div>

              <div>
                <br/>
                d.How long is Personal Data retained?
                <br/>
                Jenio may keep some of User Data for as long as reasonably necessary for Jenio’s legitimate business interests, including abusing detection and prevention and to comply with legal obligations including contractual obligations. Except for anonymized User Content, your information will be not be kept for longer than 10 years from the timing you close your account of Kiara.
              </div>

              <div>
                <br/>
                e.EU Japan Adequacy Decision
                <br/>
                Jenio complies with the Supplementary Rules under the Act on the Protection of Personal Information for the Handling of Personal Data Transferred from the EU based on an Adequacy Decision, which is the European Commission’s decision that Japan ensures an adequate level of protection of personal data pursuant to Article 45 of the General Data Protection Regulations of the European Union (“GDPR”).
              </div>
            </div>


            <div>
              <br/>
              5.For EEA users
              <br/>
              Jenio uses, processes, and stores User Data as necessary to perform contractual obligations between Jenio and you, and based on Jenio’s legitimate interests in order to provide Services. Jenio relies on your consent to process Personal Data to send marketing emails.


              <div>
                <br/>
                a.What rights do I have, and how can I exercise them?
                <br/>
                Individuals located in EEA have certain statutory rights in relation to their personal data. Subject to any exemptions provided by law, you may have the right to request access to User Data, as well as to seek to update, delete or correct this Information by contacting and requesting Jenio to do so.
                <br/>
                To the extent that Jenio’s processing of your Personal Data is subject to the GDPR, Jenio relies on its legitimate interests to process your User Data. Jenio may process your Personal Data for direct marketing purposes and you have a right to object to said use of your Personal Data for this purpose at any time.
              </div>

              <div>
                <br/>
                b.Who is our EEA Representative?
                <br/>
                Verasafe has been appointed as Jenio’s representative in the EEA for data protection matters, pursuant to Article 27 of the GDPR. VeraSafe can be contacted in matters related to the processing of Personal Data. To make such an inquiry, please contact VerSafe using this contact form: <a target="_blank" href="https://www.verasafe.com/privacy-services/contact-article-27-representative">https://www.verasafe.com/privacy-services/contact-article-27-representative</a> or via telephone at: +420 228 881 031.
              </div>
            </div>

            <div>
              <br/>
              6.Will this Privacy Policy ever change?
              <br/>
              Jenio may change this Policy from time to time to comply with laws, regulations and industry standards or to make changes to Jenio’s business. Jenio will inform you of such changes on this page. If changes are significant, Jenio will provide a more prominent notice (including, for certain services, email notification of Privacy Policy changes).
            </div>

            <div>
              <br/>
              7.Contact us
              <br/>
              You may contact Jenio with any questions relating to this Policy by submitting a help desk request here or by postal mail at:
              <br/>
              Jenio Inc.
              <br/>
              36-17 New Shibuya Mansion 202, Udagawa-cho Shibuya-ku <br/>
              Tokyo, 150-0042, Japan
            </div>
          </div>
        </div>

        <LpFooter />
      </div>
    )
  }
}
