import React from "react";
import {css} from "emotion";
import { separatorContentCss } from "../styles/main";
import happy_user from '../../../img/happy_user.jpg'
import happy_user2 from '../../../img/happy_user2.jpg'

let lpContentHappyCustomer = css`
  padding: 30px 0;

  & > .header {
    font-size: 20px;
    .main-text {
      font-size: 36px;
      color: #e63d3d;
    }
  }
  
  & > .list {
    .list-item {
      margin-top: 20px;
      margin-left: 50px;
    
      .item-title {
        color: #e63d3d;
        font-size: 24px;
      }
    }
    
    max-width: 900px;
    margin: 0 auto;
    margin-top: 30px;
    
    img{
       display: block;
       margin: 0 auto;
    }
    
    .name {
        font-size: 25px;
        color: #e63d3d;
    }
    
    .role {
        font-size: 16px;
        font-weight: bold;
    }
  }
  @media screen and (min-width:700px) { 
     .list{
         display:flex;
         flex-wrap:nowrap;
         justify-content:space-between;
     }
     .list-item+.list-item{
       margin-left:1.0em;
     }
  }
`;

export class LpContentHappyCustomer extends React.Component {
    render() {

        const list = [
            {
                title: "Our own story : Japanese vs Vietnamese",
                desc: "We are a Japanese IT company. We develop our software in Vietnam. It was hard to understand each other and asked a translator's help. But now we use Kiara for communication. When we type in Japanese, VN team are able to understand it in Vietnamese. When they type in VN, we can see Japanese translation as real-time. This is magical and we love it a lot. This direct communication has created very strong team work!"
            },
            {
                title: "Kiara is a collaboration hub for an international team",
                desc: (
                    <span>
            Business operation is becoming more international. You need to communicate with a foreign country more. A direct and real-time translation can definitely empower your international collaboration. <br/>
            Welcome to our work style innovation.</span>
                )
            },
        ];

        return (
            <div>
                <div className={lpContentHappyCustomer}>
                    <div className="header text-center">
                        <div className="main-text">Happy Customer</div>
                        <div className="sub-text">We keep improving your productivity</div>
                    </div>

                    <div className="list">
                        <div className="profile">
                            <img alt="" className="crop-default lazyloaded" src={happy_user2} width="300" height="200"/>
                        </div>
                        <div className="list-item">
                            <div>
                                <span className="name">Development Team</span><br/>
                                <span className="role">SmartNews Inc.</span><br/>
                            </div>
                            <ul>
                                <li><a target="_blank" href="https://www.smartnews.com/en/">SmartNews</a> is a very popular news app in smartphone.
                                </li>
                                <li><a target="_blank" href="https://medium.com/kiara-blog/smart-news-57644a5dec3">Blog</a>
                                </li>
                                <li>Since they have a diverse team in different locations, Kiara can leverage their productivity.</li>
                                <li>They have offices in Tokyo, Shanghai, SanFrancisco, Palo Alto, and New York.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="list">
                        <div className="profile">
                            <img alt="" className="crop-default lazyloaded" src={happy_user} width="300" height="300"/>
                        </div>
                        <div className="list-item">
                            <div>
                                <span className="name">Takahiro Nakahata</span><br/>
                                <span className="role">SmartLight KK CEO</span><br/>
                            </div>
                            <ul>
                                <li>Japanese tutorial <a target="_blank"
                                                         href="https://www.youtube.com/watch?v=zk2Q74AmK9c">YouTube</a>
                                </li>
                                <li>Translation quality is almost perfect in case of tech words. I am
                                    surprised.&nbsp;</li>
                                <li>Overall message is translated correctly.&nbsp;</li>
                                <li>Tech words like C++ and Python are translated correctly. This is very useful for my
                                    business.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={separatorContentCss}><div className="separator"/></div>
            </div>
        )
    }
}
