import React from 'react';
import {css} from 'emotion';
import {Header} from "./dashboard/Header";
import {LpFooter} from "./landing-page/footer/lp-footer";
import {lpStyle, lpPrivacyCss} from "./landing-page/styles/main";

let billingPage = css`
  .expandable-card {
    position:relative;
    margin-bottom: 16px;
  }
  
  .cardContent {
    position: relative;
    background: white;
    border-radius: 8px;
  
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    .collapsedContent {
      display: flex;
      flex-direction: row;
      padding: 24px;
    }
    .expandedContent {
      visibility:hidden;
      position:absolute;
      left: 0;
      right:0;
      bottom:0;
      opacity: 0;
      padding:24px;
      overflow:hidden;
    }
  }
  
  .expanding-card--backdrop {
    background: black;
    opacity: .50;
  }
  
  .expandable-card--title {
    font-size: 21px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 8px;
  }
  .expandable-card--sub-title {
    font-size: 14px;
    color: #888888;
  }
  
  .expandable-card--profile-image {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-primary;
    color: black;
    font-size: 21px;
    font-weight: bold;
    border-radius: 1000px;
  }
  
  .expanding-card--placeholder {
    position:relative;
    height: 0;
    visibility:hidden;
  }
`;

export class Billing extends React.Component {
    constructor() {
        super();
        this.state = {
            invoices: []
        }
    }

    componentDidMount() {
        fetch('/api/stripe/invoices', {
            method: "POST"
        })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error('bad request');
                } else {
                    return res.json();
                }
            }).then(data => {
            this.setState({invoices: data.invoices})
        }).catch(err => {
            console.log(err);
        })
    }

    render() {
        const {invoices} = this.state;
        return (
            <div className={lpStyle}>
                <Header/>
                <div className="container">
                    <div className={lpPrivacyCss}>
                        <h1>Billing History Page</h1>
                        {
                            invoices.map((invoice, i) => (
                                <div key={i} className={`${billingPage}`}>

                                    <div id="profile-1" className="expandable-card">
                                        <div className="cardContent">
                                            <div className="collapsedContent">

                                                <div className="expandable-card--right-col col">
                                                    <div className="expandable-card--title">
                                                        Receipt #{invoice.receipt_number}
                                                    </div>
                                                    <div className="expandable-card--sub-title">
                                                        <p>Amount Paid: {invoice.currency === 'usd' ? '$' : invoice.currency} {invoice.amount / 100}</p>
                                                        <p>Payment Date: {invoice.payment_date}</p>
                                                        <p>Card: {invoice.card.brand + ' - ' + invoice.card.last4}</p>
                                                        <p><a href={invoice.receipt_url}>Download Receipt</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="expandedContent">
                                                <div className="mock-content"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        {
                            (invoices.length === 0) ?

                            <p>No Billing Data</p> :
                            <p></p>
                        }
                    </div>
                </div>

                <LpFooter/>
            </div>
        );

    }
}